###
All files represented in hierachical form
TODO: Replace temporary content with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import {matchPath} from 'react-router-dom'
import {FormatterConsumer} from 'libs/formatter'
import printJS from 'print-js'

# Renderable
import { div, p, ul, li, small, h1, span, h2, a } from 'react-dom-factories'

import { NotificationConsumer } from '../../application/components/NotificationManager'

Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _Info from './components/Info'
Info = React.createFactory _Info

import _DocumentHeader from '@bevy/document-header'
DocumentHeader = React.createFactory _DocumentHeader

import _KPIToolbar from '@bevy/kpi-toolbar'
KPIToolbar = React.createFactory _KPIToolbar

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _HeaderBar from 'components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _LinkDocumentScan from 'parts/LinkDocumentScan'
LinkDocumentScan = React.createFactory _LinkDocumentScan

import _DocumentReassign from 'parts/DocumentReassign'
DocumentReassign = React.createFactory _DocumentReassign

import _DeleteDocument from 'parts/DeleteDocument'
DeleteDocumentModal = React.createFactory _DeleteDocument

import _ShareModal from 'parts/ShareModal'
ShareModal = React.createFactory _ShareModal

import _DocumentRegisterStatus from 'components/DocumentRegisterStatus'
DocumentRegisterStatus = React.createFactory _DocumentRegisterStatus

import __PDFPreview from '@bevy/pdf-preview'
PDFPreview = React.createFactory __PDFPreview

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem

import _Modal, {
	ModalHeader as _ModalHeader
	ModalTitle as _ModalTitle
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalTitle = React.createFactory _ModalTitle
ModalTransition = React.createFactory _ModalTransition

# Data
import {
	DeleteDocument
	UpdateReviewed
	UpdateApproved
	DocumentPreview
	DocumentPreviewAdditonalInfo
	DuplicateDocument
	ReassignDocument
	MapScan
	ListDocumentScans
	UpdateDate
	UpdateIdentifier
	UpdateRecipient
	UpdateParticipants
	UpdateDescription
	UpdateBuildings
	getAccessList
	UpdateACL
} from './data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'
import {
	chevronLeft
	link2
	paperclip
	messageSquare
	share2
	tag
	file
	edit
	download
	box
	user
	checkCircle
	archive
	trash2
	filePlus
	xCircle
	arrowLeft
} from 'react-icons-kit/feather'

export default class ProjectDocumentsView extends React.Component
	constructor: (props) ->
		super props
		@state =
			activeModal: null
			isLoadingQRSticker: false
			isLoadingQRCover: false

	getConjectPath: (path, short = false) ->
		fullPath = []
		if path?
			_.map path.ancestors, (ancestor) ->
				ancestorCode = if ancestor.code < 10 then "0#{ancestor.code}" else ancestor.code
				if short then fullPath.push ancestorCode else fullPath.push("#{ancestorCode} #{ancestor.name}")
			pathCode = if path.code < 10 then "0#{path.code}" else path.code
			if short then fullPath.push pathCode else fullPath.push("#{pathCode} #{path.name}")
			_.toUpper _.join fullPath, ' / '
		else
			'-'

	handleOptimisticResponse: (legalDocument, mutationName, updatedItem) ->
		__typename: 'Mutation'
		"#{mutationName}": {
			...legalDocument
			# __typename: "LegalDocument"
			# id: legalDocument.id
			# status: legalDocument.status
			...updatedItem
			modifiedAt: moment().unix()
			modifiedBy: legalDocument.modifiedBy
		}

	handleQRPrint: (format, {legalDocument}) =>
		@setState "isLoadingQR#{_.capitalize format}": true

		document =
			id: legalDocument.id
			url: "#{window.location.origin}/legalDocuments/#{legalDocument.id}"
			project: legalDocument.project.name
			company: legalDocument.company.name
			source: legalDocument.correspondent.name
			date: _.find(legalDocument.dates, type: 'ReceivalDate').value
			form: legalDocument.form.name
			topic: legalDocument.topic.name
			fullPath: @getConjectPath legalDocument.path
			shortPath: @getConjectPath legalDocument.path, true

		url = "https://itd8ulukq5.execute-api.eu-central-1.amazonaws.com/dev?format=#{format}&document=#{encodeURIComponent JSON.stringify document}"
		fetch url,
			mode: 'no-cors'
		.then (data) =>
			printJS
				printable: "https://s3.eu-central-1.amazonaws.com/s360-dms-qr-tags/#{legalDocument.id}/#{format}.pdf"
				type: 'pdf'
				onLoadingStart: () -> # leave to display print window
				onLoadingEnd: () => @setState "isLoadingQR#{_.capitalize format}": false
				onError: () => @setState "isLoadingQR#{_.capitalize format}": false

	render: ->
		legalDocumentsCollectionQueries = ['ProjectLegalDocumentsList', 'ProjectLegalDocumentsActivity', 'ProjectLegalDocumentsAssigned']
		NotificationConsumer {}, ({setNotification}) =>
			Mutation {
				mutation: ReassignDocument
				refetchQueries: _.concat legalDocumentsCollectionQueries, [
					query: DocumentPreview
					variables:
						documentID: @props.match.params.document
				]
			}, (reassignDocument, {loading}) =>
				Mutation {
					mutation: DeleteDocument
					refetchQueries: _.concat legalDocumentsCollectionQueries, ['ProjectLegalDocumentsPathsAndStats']
					onCompleted: =>
						@props.history.goBack()
						setNotification({content: 'Document was deleted!', appearance: 'success'})
				}, (deleteDocument) =>
					Mutation {
						mutation: DuplicateDocument
						refetchQueries: legalDocumentsCollectionQueries
						onCompleted: (data) =>
							setNotification({content: 'Duplicate was sucessfully created!', appearance: 'success'})
							pathName = _.join (_.dropRight _.dropRightWhile (_.split @props.location.pathname, '/'), (part) => part isnt @props.match.params.document), '/'
							@props.history.push "#{pathName}/#{data.legalDocumentCreateByCategory.id}"
					}, (createDuplicate) =>
						Mutation {
							mutation: UpdateApproved
							refetchQueries: legalDocumentsCollectionQueries
							onCompleted: =>
								setNotification
									content: 'Document was marked as approved and was moved to archive!'
									appearance: 'success'
								@props.history.push "/projects/#{@props.match.params.project}/legal/archive/#{@props.match.params.document}"
						}, (updateApproved) =>
							Mutation {
								mutation: UpdateReviewed
								refetchQueries: legalDocumentsCollectionQueries
								onCompleted: (data) =>
									setNotification
										content: if data.legalDocumentUpdateReviewed.hasReview
											'Document was marked as Reviewed!'
										else
											'Document was rejected from review!'
										appearance: 'success'
							}, (updateReviewed) =>
								Mutation { mutation: MapScan, refetchQueries:  _.concat [ 'legalDocumentScans' ], legalDocumentsCollectionQueries }, (linkScan) =>
									Mutation { mutation: UpdateBuildings, refetchQueries: legalDocumentsCollectionQueries }, (updateBuildings) =>
										Mutation { mutation: UpdateDate, refetchQueries: legalDocumentsCollectionQueries }, (updateDate) =>
											Mutation { mutation: UpdateIdentifier, refetchQueries: legalDocumentsCollectionQueries }, (updateIdentifier) =>
												Mutation { mutation: UpdateRecipient, refetchQueries: legalDocumentsCollectionQueries }, (updateRecipient) =>
													Mutation { mutation: UpdateParticipants, refetchQueries: legalDocumentsCollectionQueries }, (updateParticipants) =>
														Mutation { mutation: UpdateDescription, refetchQueries: legalDocumentsCollectionQueries }, (updateDescription) =>
															Mutation {
																mutation: UpdateACL
																refetchQueries: _.concat ['getAccessList'], legalDocumentsCollectionQueries
															}, (updateACL) =>
																Query
																	query: DocumentPreview
																	variables:
																		documentID: @props.match.params.document
																, ({ loading, data, error}) =>
																	if loading
																		return div {}, 'Loading'
																	if error
																		return div {}, 'Error'
																	{legalDocument, project} = data
																	pathName = _.join (_.dropRight _.dropRightWhile (_.split @props.location.pathname, '/'), (part) => part isnt @props.match.params.document), '/'
																	Fragment {},
																		LinkDocumentScan {
																			...@props
																			documentID: @props.match.params.document
																			active: @state.activeModal is 'linkScan'
																			linkScan: linkScan
																			onDone: => @setState activeModal: null
																		}
																		DocumentReassign {
																			...@props
																			currentAssignee: data.legalDocument.asignee
																			active: @state.activeModal is 'reassign'
																			onConfirm: (assignee, comment) ->
																				reassignDocument
																					variables:
																						documentID: data.legalDocument.id
																						assignee:
																							type: assignee.__typename
																							id: assignee.id
																			onDone: => @setState activeModal: null
																		}

																		ModalTransition {},
																			if @state.activeModal is 'share'
																				ShareModal {
																					...@props
																					active: @state.activeModal is 'share'
																					documentID: @props.match.params.document
																					acl: data.legalDocument.acl
																					onDone: (newACL) =>
																						updateACL
																							variables:
																								documentID: @props.match.params.document
																								value: _.map newACL, (access) ->
																									user: access.user.id
																									level: access.access.level
																					onClose: => @setState activeModal: null
																				}

																		DeleteDocumentModal {
																			...@props
																			documentID: @props.match.params.document
																			active: @state.activeModal is 'delete'
																			onConfirm: (documentID) ->
																				deleteDocument
																					variables:
																						documentID: documentID
																			onDone: => @setState activeModal: null
																		}

																		ModalTransition {},
																			do => if @state.activeModal is 'duplicate'
																				Modal {
																					width: 'small'
																					height: 200
																					shouldCloseOnEscapePress: false
																					shouldCloseOnOverlayClick: false
																					onClose: => @setState activeModal: null
																					appearance: 'warning'
																					header: ({showKeyline}) =>
																						ModalHeader {showKeyline: showKeyline},
																							ModalTitle {}, 'Duplicate document'
																					actions: [
																						text: 'Create duplicate'
																						onClick: =>
																							@setState activeModal: null
																							createDuplicate
																								variables:
																									basics:
																										companyID: legalDocument.company.id
																										correspondentID: legalDocument.correspondent.id
																										receivalDate: _.find(legalDocument.dates, type: "ReceivalDate").value
																										description: legalDocument.description
																										formID: legalDocument.form.id
																										topicID: legalDocument.topic.id
																										pathID: legalDocument.path.id
																										parentDocumentID: if legalDocument.parentDocument? then legalDocument.parentDocument.id
																										originalDocumentID: if legalDocument.originalDocument? then legalDocument.originalDocument.id else legalDocument.id
																									details:
																										#TODO check
																										buildings: legalDocument.buildings
																										dates: _.map legalDocument.dates, (date) -> {type: date.type, value: date.value}
																										identifiers: _.map legalDocument.identifiers, (identifier) -> {type: identifier.type, value: identifier.value}
																										entities:
																											recipientID: legalDocument.entities.recipient?.id
																											participantsIDs: _.map legalDocument.entities.participants, (participant) -> participant.id
																					,
																						text: 'Cancel'
																						onClick: => @setState activeModal: null
																					]
																				}, 'Are you sure you want to duplicate this document?'
																		HeaderBar {
																			appearance: 'subtle'
																			document: true
																			header:
																				DocumentHeader
																					type: legalDocument.form.name
																					lastUpdateShort: @props.mobile
																					action:
																						if !@props.mobile
																							console.log legalDocument.status is 'Approved'
																							Button {
																								appearance: 'subtle'
																								spacing: 'none'
																								tooltip: do ->
																									if legalDocument.status is 'Approved'
																										'Go to Archive'
																									else
																										'Go to Inbox'
																								href: do =>
																									if legalDocument.status is 'Approved'
																										"/projects/#{@props.match.params.project}/legal/archive"
																									else
																										"/projects/#{@props.match.params.project}/legal/documents"
																								iconBefore: Icon
																									icon: do ->
																										if legalDocument.status is 'Approved'
																											arrowLeft
																										else
																											arrowLeft
																									size: 24
																							}
																					className: styles.documentHeader
																					title: _.capitalize legalDocument.topic.name
																					description: legalDocument.description
																					sync: 'Saved'
																					status: do ->
																						if legalDocument.status is 'Approved'
																							name: 'Archived'
																							appearance: 'default'
																						else
																							name: if legalDocument.isUrgent then 'Urgent' else 'Draft'
																							appearance: if legalDocument.isUrgent then 'removed' else 'inprogress'
																						# 	when 'New', 'PendingCheck' then 'default'
																						# 	when 'Checked' then 'inprogress'
																						# 	when 'OpenIssue' then 'moved'
																						# 	when 'OverdueIssue' then 'removed'
																						# 	when 'Resolved' then 'success'
																					# author:
																					# 	name: legalDocument.modifiedBy.name
																					# 	image: if legalDocument.modifiedBy.picture? then legalDocument.modifiedBy.picture.mini
																					# lastUpdate: legalDocument.modifiedAt
																					side:
																						div {className: styles.avatarsWrapper},
																							# AvatarItem
																							Avatar {
																								name: "Assigned to: #{legalDocument.asignee.name}"
																								src: legalDocument.asignee.picture.mini
																								size: 'medium'
																							}
																							do ->
																								sharedWith = _.reject(legalDocument.acl, userID: legalDocument.asignee.id)
																								if !_.isEmpty sharedWith
																									Fragment {},
																										div {className: styles.group},
																											div {className: styles.groupWrapper}, _.map sharedWith, (access, index) ->
																												if index < 4
																													div {
																														className: styles.groupedAvatar
																														# style: transform: "translate(-#{index*70}%, 0)"
																														style:
																															width: '15px'
																														key: access.user.name
																													},
																														# AvatarItem
																														Avatar {
																															name: access.user.name
																															src: access.user.picture.mini
																															size: 'small'
																														}
																										if _.size(sharedWith) > 4
																											div {className: styles.tooltipContainer},
																												Tooltip {content: (_.map _.drop(sharedWith, 4), (access) -> access.user.name ).join(' ')},
																													div {className: styles.aclCountWrapper},
																														div {className: styles.aclCount}, "+#{_.size(sharedWith) - 4}"
																			descriptor: do =>
																				FormatterConsumer {}, (format) =>
																					commonKPIs = _.compact [
																							key: 'receivedDate'
																							description: trLegal.props.receivalDate
																							tooltip: 'Date when document was received'
																							value: format.date (_.find(legalDocument.dates, type: 'ReceivalDate').value)
																						,
																							if legalDocument?.correspondent?
																								key: 'source'
																								description: trLegal.props.source
																								tooltip: 'Source of document'
																								value: legalDocument.correspondent.name
																						]
																					console.log legalDocument

																					draftKPIs = if !legalDocument.hasApproval then _.compact [
																							# 	key: 'registrationStatus'
																							# 	description: trLegal.props.status
																							# 	className: styles.centeredValue
																							# 	tooltip: "Currently: #{_.capitalize _.lowerCase legalDocument.status}"
																							# 	value: DocumentRegisterStatus
																							# 		status: legalDocument.status
																							# 		hasScan: legalDocument.hasScan
																							# ,
																								key: 'scan'
																								description: trLegal.props.hasScan
																								tooltip: 'If scan is attached'
																								value: if legalDocument.hasScan then 'Yes' else 'No'
																							,
																								key: 'asignee'
																								description: trLegal.props.assignee
																								tooltip: 'User responsible for next action on the document'
																								value: legalDocument.asignee.name
																							,
																								if legalDocument.location?
																									key: 'location'
																									description: trLegal.props.location
																									tooltip: 'Location of a physical document'
																									value: legalDocument.location
																							,
																								if data.legalDocument.parentDocument?.id?
																									key: 'parent'
																									description: trLegal.props.mainContract
																									tooltip: 'Main contract for this document'
																									value:
																										Link {to: "#{pathName}/#{data.legalDocument.parentDocument.id}"},
																											_.find(data.legalDocument.parentDocument.identifiers, type: 'Number').value
																							,
																								if data.legalDocument.originalDocument?
																									key: 'originalDocument'
																									description: trLegal.props.originalDocument
																									tooltip: 'Original document'
																									value:
																										Link {to: "#{pathName}/#{data.legalDocument.originalDocument.id}"}, 'Original document'
																							]
																					commitedKPIs = if legalDocument.hasApproval then  _.compact [
																							key: 'approvedAt'
																							description: trLegal.props.approvedAt
																							tooltip: 'Date when document was approved'
																							value: format.date legalDocument.approvedAt
																						,
																							key: 'approvedBy'
																							description: trLegal.props.approvedBy
																							tooltip: 'User responsible for approving this document'
																							value: legalDocument.approvedBy.name
																						]

																					KPIToolbar
																						className: styles.kpis
																						items: _.compact _.concat commonKPIs, commitedKPIs, draftKPIs
																			toolbar:
																				if !@props.mobile or !@props.mobile?
																					Toolbox
																						borderless: true
																						actions: _.compact [
																							# 	Button {
																							# 		tooltip: 'Print QR sticker'
																							# 		isLoading: @state.isLoadingQRSticker
																							# 		# isDisabled: !legalDocument.hasScan
																							# 		appearance: 'subtle'
																							# 		onClick: => @handleQRPrint 'sticker', data
																							# 		iconBefore:
																							# 			Icon
																							# 				icon: tag
																							# 				size: 18
																							# 	}, 'Print QR Sticker'
																							# ,
																								Button {
																									tooltip: 'Print QR cover'
																									isLoading: @state.isLoadingQRCover
																									appearance: 'subtle'
																									# isDisabled: !legalDocument.hasScan
																									onClick: => @handleQRPrint 'cover', data
																									iconBefore:
																										Icon
																											icon: file
																											size: 18
																								}, 'Print QR Cover'
																							,
																								if !@props.isArchived?
																									Button {
																										tooltip: 'Match document\'s scan from unrecognized scans in the system'
																										appearance: 'subtle'
																										onClick: => @setState activeModal: 'linkScan'
																										iconBefore:
																											Icon
																												icon: link2
																												size: 18
																									}, 'Match scan'
																							,
																								a
																									className: styles.unstyledLink
																									href: if legalDocument.scan?.original? then "#{legalDocument.scan.original}"
																									target: '_blank'
																								,
																									Button {
																										tooltip: 'Download scan'
																										appearance: 'subtle'
																										isDisabled: !legalDocument.hasScan
																										iconBefore:
																											Icon
																												icon: download
																												size: 18
																									}, 'Download scan'
																							,
																								if !legalDocument.hasReview and !legalDocument.hasApproval
																									Button {
																										tooltip: if _.every [legalDocument.hasScan,legalDocument.hasCategory,legalDocument.hasDetails]
																												'Mark document as reviewed'
																											else
																												content = 'To be able to mark this document as reviewed add: '
																												if !legalDocument.hasScan then content = _.concat content, 'scan, '
																												if !legalDocument.hasCategory then content = _.concat content, 'basic informations, '
																												if !legalDocument.hasDetails then content = _.concat content, 'details, '
																												_.trimEnd (_.join content, ''), ', '
																										appearance: 'subtle'
																										isDisabled: !_.every [legalDocument.hasScan,legalDocument.hasCategory,legalDocument.hasDetails]
																										onClick: ->
																											updateReviewed variables:
																												documentID: legalDocument.id
																												reviewed: true
																										iconBefore:
																											Icon
																												icon: checkCircle
																												size: 18
																									}, 'Mark reviewed'
																							,
																								if legalDocument.hasReview and !legalDocument.hasApproval
																									Button {
																										tooltip: 'Mark document as approved'
																										appearance: 'subtle'
																										onClick: ->
																											updateApproved variables:
																												documentID: legalDocument.id
																												approved: true
																										iconBefore:
																											Icon
																												icon: checkCircle
																												size: 18
																									}, 'Approve'
																							,
																								if legalDocument.hasReview and !legalDocument.hasApproval
																									Button {
																										tooltip: 'Reject document from review'
																										appearance: 'subtle'
																										onClick: ->
																											updateReviewed variables:
																												documentID: legalDocument.id
																												reviewed: false
																										iconBefore:
																											Icon
																												icon: xCircle
																												size: 18
																									}, 'Reject'
																							,
																								if !@props.isArchived?
																									Button {
																										tooltip: 'Reassign document'
																										appearance: 'subtle'
																										onClick: => @setState activeModal: 'reassign'
																										iconBefore:
																											Icon
																												icon: user
																												size: 18
																									}, 'Reassign'
																							,
																								Button {
																									tooltip: 'Delete document'
																									appearance: 'subtle'
																									onClick: => @setState activeModal: 'delete'
																									iconBefore:
																										Icon
																											icon: trash2
																											size: 18
																								}, 'Delete document'
																							,
																								if !@props.isArchived?
																									Button {
																										tooltip: 'Create document duplicate'
																										appearance: 'subtle'
																										onClick: => @setState activeModal: 'duplicate'
																										iconBefore:
																											Icon
																												icon: filePlus
																												size: 18
																									}, 'Duplicate'
																							,
																								Button {
																									tooltip: 'Share document'
																									appearance: 'subtle'
																									onClick: => @setState activeModal: 'share'
																									iconBefore:
																										Icon
																											icon: share2
																											size: 18
																								}, 'Share'
																						]
																		}
																			RouterTabs
																				location: location
																				tabs: [
																						id: 0
																						label: 'Info'
																						href: "#{@props.match.url}"
																						exact: true
																						content:
																							Query
																								query: DocumentPreviewAdditonalInfo
																								variables:
																									documentID: @props.match.params.document
																							, ({ loading, data, error}) =>
																									if loading or error?
																										return div {}, 'Loading...'
																									else
																										React.createElement Info,
																											document: {...legalDocument, ...data.legalDocument}
																											isArchived: @props.isArchived
																											onDateUpdate: (type, value) =>
																												dates = _.clone data.legalDocument.dates
																												changedDate = _.find dates, (date) -> date.type is type
																												if changedDate?
																													changedDate.value = value
																												else
																													dates.push
																														value: value
																														type: type
																												updateDate
																													#FIXME glitch due to lack of assignee
																													# optimisticResponse: @handleOptimisticResponse(
																													# 	legalDocument, 'legalDocumentUpdateDate', {dates: dates},
																													# )
																													variables:
																														documentID: data.legalDocument.id
																														type: type
																														value: value
																											onIdentifierUpdate: (type, value) =>
																												identifiers = _.clone legalDocument.identifiers
																												changedIdentifier = _.find identifiers, type: type
																												if changedIdentifier?
																													changedIdentifier.value = value
																												else
																													identifiers.push
																														value: value
																														type: type
																												updateIdentifier
																													#FIXME glitch due to lack of assignee
																													# optimisticResponse: @handleOptimisticResponse(
																													# 	legalDocument, 'legalDocumentUpdateIdentifier', {identifiers: identifiers},
																													# )
																													variables:
																														documentID: data.legalDocument.id
																														type: type
																														value: value
																											onRecipientUpdate: (newRecipient) =>
																												updateRecipient
																													optimisticResponse: @handleOptimisticResponse(
																														legalDocument,
																														'legalDocumentUpdateRecipient',
																														{entities:{
																															...data.legalDocument.entities,
																															recipient: newRecipient
																															}
																														},
																													)
																													variables:
																														documentID: data.legalDocument.id
																														value: newRecipient.id
																											onParticipantsUpdate: (newParticipants) =>
																												updateParticipants
																													optimisticResponse: @handleOptimisticResponse(
																														legalDocument,
																														'legalDocumentUpdateParticipants',
																														{entities:{
																															...data.legalDocument.entities,
																															participants: newParticipants
																															}
																														},
																													)
																													variables:
																														documentID: data.legalDocument.id
																														value: _.map newParticipants, 'id'
																											onBuildingsUpdate: (newBuildings) =>
																												updateBuildings
																													variables:
																														documentID: data.legalDocument.id
																														value: _.map newBuildings, 'value'
																													optimisticResponse: @handleOptimisticResponse legalDocument, 'legalDocumentUpdateBuildings', buildings: newBuildings
																											onDescriptionUpdate: (value) =>
																												updateDescription
																													optimisticResponse: @handleOptimisticResponse(
																														legalDocument, 'legalDocumentUpdateDescription', {description: value},
																													)
																													variables:
																														documentID: data.legalDocument.id
																														value: value
																					,
																						id: 1
																						label: 'Preview'
																						href: "#{@props.match.url}/preview"
																						exact: true
																						content:
																							if legalDocument.scan?.original?
																								div {className: styles.centered},
																									PDFPreview
																										scan: legalDocument.scan.original
																										enableRotating: !@props.mobile
																										enableScaling: !@props.mobile
																										scale: if @props.mobile then 0.5
																							else
																								div {className: styles.tabPanel},
																									SkeletonBox {className: styles.itemBox}, 'No scan provided'
																				]
