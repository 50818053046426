###
Button component with additional functionalties:
- tooltip info
- linking functionality with react router

Component uses https://atlaskit.atlassian.com/packages/core/button as base implemenetation

TODO: Button should support data-test-id by default
TODO!: Better support for disabled attribute on link
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import _ButtonElement from '@atlaskit/button'
ButtonElement = React.createFactory _ButtonElement
# Styles
import styles from './index.styl'

export default class ButtonExtra extends React.Component
	@propTypes:
		tooltip: PropTypes.node
		href: PropTypes.string
		appearance: PropTypes.oneOf [
			'default'
			'danger'
			'link'
			'primary'
			'subtle'
			'subtle-link'
			'warning'
			'help'
			'primary-action' # Equals to subtle + primary color for text
		]
		target: PropTypes.oneOfType [
			PropTypes.oneOf [
				'_blank'
				'_self'
				'_parent'
				'_top'
			]
			PropTypes.string
		]
	@defaultProps: {}
	render: ->
		content = ButtonElement {
			..._.omit @props, ['tooltip', 'appearance']
			className: cnames [
				@props.className
				if @props.appearance? and @props.appearance is 'primary-action' then styles.primaryAction
			]
			appearance: do =>
				if @props.appearance?
					if @props.appearance is 'primary-action'
						'subtle'
					else
						@props.appearance
			component: do =>
				if @props.href?
					(props) =>
						{className, style, children, href, target} = props
						if @props.component?
							Link {className: cnames [ styles.internalLink, if @props.isDisabled then styles.disabled ], to: href, target: target},
								@props.component {...props}, children
						else
							Link {...props, to: href, target: target, className: cnames [props.className, if @props.isDisabled then styles.disabled]}, children
				else if @props.component?
					@props.component
		}
		if @props.tooltip?
			Tooltip {
				content: @props.tooltip
			},
				content
		else
			content



