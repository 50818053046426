###
KPI Toolbar presents multiple kpis in common way
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'

import _KPI from '@bevy/kpi'
KPI = React.createFactory _KPI
# Styles
import styles from './index.styl'



export default class KPIToolbar extends React.Component
	@propTypes:
		compact: PropTypes.bool

	# @defaultProps:

	constructor: (props) ->
		super props
	render: ->
		div {
			className: cnames [
				@props.className
				if @props.compact then styles.compact
				styles.wrapper
			]
			'data-test-id': @props['data-test-id']
			style: @props.style
		},
			_.map @props.items, (item) =>
				KPI {
					..._.omit item, 'value'
					className: cnames [styles.item, item.className]
				}, item.value
