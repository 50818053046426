###
document reassign modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTitle as _ModalTitle
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter
ModalTitle = React.createFactory _ModalTitle
ModalTransition = React.createFactory _ModalTransition

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

# Styles
import styles from './index.styl'

export default class DocumentDeleteModal extends React.Component
	constructor: (props) ->
		super props

	handleDeleteButtonClick: () =>
		@props.onConfirm(@props.documentID)
		@props.onDone()

	render: ->
		selectStyle =
			menuPortal:
				(base) ->
					{
						...base
						zIndex: 9999
					}
		ModalTransition {},
			do => if @props.active
				Modal {
					width: 'small'
					height: 200
					shouldCloseOnEscapePress: false
					shouldCloseOnOverlayClick: false
					appearance: 'danger'
					onClose: @props.onDone
					heading: 'Delete document'
					actions: [
						text: 'Delete'
						onClick: () => @handleDeleteButtonClick()
					,
						text: 'Cancel'
						onClick: () => @props.onDone()
					]
				},
					div {className: cnames styles.base},
						'Are you sure you want to delete this document?'
