###
Card component to display document details
###

# Libs
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, button, span, p, a, img } from 'react-dom-factories'
import { Icon } from 'react-icons-kit'

import _Badge from '@atlaskit/badge'
Badge = React.createFactory _Badge

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Document as _Document, Page as _Page } from 'react-pdf'
Document = React.createFactory _Document
Page = React.createFactory _Page

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

#Styles
import styles from './index.styl'
import {
	file
} from 'react-icons-kit/feather'


export default class ScanCard extends React.Component
	@defaultProps =
		icon: Icon {icon: file}

	@propTypes =
		title: PropTypes.node.isRequired
		icon: PropTypes.node
		description: PropTypes.node
		actions: PropTypes.node

	constructor: (props) ->
		super props
		@state =
			isLoaded: false

	render: ->
		div { onClick: @props.onClick, className: cnames 'bevy-scan-card', @props.className, styles.base },
			if @props.preview?
				div {className: cnames styles.preview},
					Document
						file: @props.preview
						loading: Spinner {}
						onLoadSuccess: () => @setState isLoaded: true
					,
						Page
							className: cnames styles.pdfDocument, 'bevy-scan-card-pdf-preview'
							pageNumber: 1
							scale: 0.25
			div {className: cnames styles.detail},
				div {className: cnames styles.icon},
					@props.icon
				div {className: cnames styles.info},
					div {className: cnames styles.title}, @props.title
					if @props.description?
						div {className: cnames styles.description}, @props.description
				if @props.actions?
					div {className: styles.actions}, @props.actions
