###
Simple clickable wrapper over https://atlaskit.atlassian.com/packages/core/blanket
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'
# Styles
import styles from './index.styl'


export default class Blanket extends React.Component
	@propTypes:
		onClick: PropTypes.func
	@defaultProps: {}
	constructor: (props) ->
		super props
	render: ->
		div {
			onClick: @props.onClick
			className: cnames [
				styles.base
				if @props.active then styles.active
			]
		}
