###
Drawer with all entrypoints to S360 platform. Drawer should contain all applications,
recently used applications and workflows.
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
# Renderable
import { h2 } from 'react-dom-factories'

import _AppSwitcher from '@bevy/app-switcher'
AppSwitcher = React.createFactory _AppSwitcher

import _Drawer from '@atlaskit/drawer'
Drawer = React.createFactory _Drawer

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Data
import { RootQuery } from './data'
import homeImg from '../../../assets/placeholder.png'
import buildingImg from '../../../assets/cityscape.png'
import documentImg from '../../../assets/archive.png'

# Styles
import styles from './index.styl'
import {
	home
	box
	briefcase
	alignJustify
	fileText
} from 'react-icons-kit/feather'


export default class ApplicationSwitcherDrawer extends React.Component
	@propTypes:
		close: PropTypes.func
		isOpen: PropTypes.bool
		headerComponent: PropTypes.func
	@defaultProps:
		headerComponent: h2

	constructor: (props) ->
		super(props)
		@state =
			expandedDrawer: false

	handleAppSwitcherExpand: =>
		@setState expandedDrawer: !@state.expandedDrawer

	handleClose: =>
		@setState expandedDrawer: false
		@props.close()

	render: ->
		Drawer
			onClose: @handleClose
			isOpen: @props.isOpen
			width: if @state.expandedDrawer then 'full' else 'medium'
		,
			Query
				query: RootQuery
			, ({data, loading}) =>
				if loading
					Spinner {}
				else
					console.log data
					applications = [
						name: 'Home'
						icon: home
						bigIcon: homeImg
						description: 'Description'
						href: '/'
					# ,
					# 	name: 'Projects'
					# 	icon: alignJustify
					# 	bigIcon: buildingImg
					# 	description: 'Description'
					# 	href: '/projects'
					]
					projects = _.map data.projects, (project) ->
						name: project.name
						icon: box
						description: project.city
						href: "/projects/#{project.slug}"
						image: project.photo.original

					AppSwitcher
						className: styles.appSwitcher
						accessPoints: [
							name: 'Applications'
							items: applications
						,
							name: 'Projects'
							items: projects
						]
						onExpand: @handleAppSwitcherExpand
						onSelect: @handleClose
						onAddToFavourites: () ->
