import gql from 'graphql-tag'

export LoggedInUser = gql """
	query {
		me {
			id
			name
			globalRoles { name, scopes }
			projectsPermissions {
				projectID
				access { scopes, level }
			}
		}
	}
"""
