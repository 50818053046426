###
Progress status

###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import { div } from 'react-dom-factories'

# Styles
import styles from './index.styl'

export default class ProgressStatus extends React.Component
	@propTypes:
		progress: PropTypes.number

	render: ->
		div {className: cnames styles.base},
			div {className: cnames styles.text},
				if @props.text?
					@props.text
			if @props.progress?
				div {className: cnames styles.progressBarEmpty},
					div {className: cnames(styles.progressBarFill), style: width: "#{@props.progress}%"}
					_.map [0..3], (i) ->
						div
							key: i
							className: cnames styles.separator
							style: 'left': "calc(#{i*20}% + 15px)"
