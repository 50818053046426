###
DocumentregisterStauts Wrapper Progress pill

###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'

# Renderable
import { div } from 'react-dom-factories'

import _ProgressStatus from '@bevy/progress-status'
ProgressStatus = React.createFactory _ProgressStatus

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

# Styles
import { check } from 'react-icons-kit/feather'

# Data
import trLegal from 'data/local/legal'


export default class DocumentRegisterStatus extends React.Component
	handleStatus: (status, hasScan) ->
		# console.log status, hasScan
		switch status
			when 'Categorized'
				Lozenge {appearance: 'moved'},  _.toUpper trLegal.statuses.Categorized
			when 'Filled'
				if !hasScan
					ProgressStatus
						text: _.toUpper trLegal.statuses.Filled_noScan
						progress: 40
				else
					Lozenge {appearance: 'inprogress'},  _.toUpper trLegal.statuses.Filled
			when 'Reviewed'
				ProgressStatus
					text: _.toUpper trLegal.statuses.Reviewed
					progress: 80
			else
				Icon
					icon: check
					size: 16

	render: ->
		@handleStatus(@props.status, @props.hasScan)
