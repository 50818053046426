###
Wrapper over AtlasKit (https://atlaskit.atlassian.com/packages/core/spinner) spinner with center aliging in parent container
###

# Libs
import React from 'react'
# Renderable
import { div } from 'react-dom-factories'

import _AtlaskitSpinner from '@atlaskit/spinner'
AtlaskitSpinner = React.createFactory _AtlaskitSpinner


export default class Spinner extends React.Component
	render: ->
		div {
			style: {
				...@props.style
				width: '100%'
				height: '100%'
				display: 'flex'
				position: 'absolute'
				top: 0
				left: 0
				alignItems: 'center'
				justifyContent: 'center'
			}
		},
			AtlaskitSpinner {...@props}
