export MetaInfo = '''
	createdAt
	createdBy {
		...on Person {
			name
			id
			picture {
				mini
			}
		}
	}
	modifiedAt
	modifiedBy {
		...on Person {
			name
			id
			picture {
				mini
			}
		}
	}
	approvedAt
	approvedBy {
		id
		name
	}
	description
'''
export LegalDocument = '''
	id
	company {
		id
		name
	}
	project { id, name }
	correspondent {
		id
		name
	}
	location
	form {
		id
		name
	}
	topic {
		id
		name
	}
	hasScan
	scan {
		id
		original
	}
	hasCategory
	hasDetails
	hasReview
	hasApproval
	isUrgent
'''

export LegalDocumentHeader = '''
	dates {
		type
		value
	}
	status
	location
	parentDocument { id, identifiers { type, value } }
	originalDocument { id, form {id, name} }
	path {
		id
		code
		name
		ancestors {
			id
			name
			code
		}
		full
	}
	asignee {
	... on Person {
		id
		name
		picture {mini}
	}
	... on Service {
		id
		name
	}
	}
'''

export LegalDocumentAdditionalInfo = '''
	id
	buildings
	dates { type, value }
	identifiers { type, value }
	entities {
		participants { id, name }
		recipient { id, name }
	}
'''

export ACL = '''
	acl {
		user {
			... on Person {
				id
				name
				picture {
					mini
				}
			}
		}
		userID
		access {
			scopes
			level
		}
	}
'''
