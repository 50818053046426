import gql from 'graphql-tag'

export getProjectMembers = gql """
	query($projectSlug: String!){
		project(slug: $projectSlug) {
			id
			members {
				... on Person {
					id
					name
					picture {
						mini
					}
				}
			}

		}
	}
"""

