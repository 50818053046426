###
Registering modal for confidential and private correspondence
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, p, table, tr, th, td } from 'react-dom-factories'

import { NotificationConsumer } from '../../application/components/NotificationManager'

Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem

import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTitle as _ModalTitle
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter
ModalTitle = React.createFactory _ModalTitle
ModalTransition = React.createFactory _ModalTransition

import _Select from '@atlaskit/select'
Select = React.createFactory _Select

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Data
import {
	getProjectMembers
} from './data'

# Styles
import styles from './index.styl'
import {
	x
} from 'react-icons-kit/feather'


export default class DocumentShareModal extends React.Component
	@propTypes =
		onDone: PropTypes.func.isRequired

	constructor: (props) ->
		super props
		@state =
			acl: @props.acl

	render: ->
		selectStyle =
			menuPortal:
				(base) ->
					{
						...base
						zIndex: 9999
					}
			control:
				(base) ->
					{
						...base
						backgroundColor: 'transparent'
						borderColor: 'transparent'
						color: '#091e42'
					}

		Modal {
			width: 'medium'
			shouldCloseOnEscapePress: false
			shouldCloseOnOverlayClick: false
			onClose: @props.onClose
			autoFocus: false
			header: ({showKeyline}) =>
				ModalHeader
					showKeyline: showKeyline
				,
					ModalTitle {}, 'Shared with'

			footer: ({showKeyline, onClose}) =>
				ModalFooter {showKeyline: showKeyline},
					div {className: styles.actions},
						Button
							isDisabled: @props.acl is @state.acl
							appearance: 'primary'
							onClick: () =>
								@props.onDone @state.acl
								@props.onClose()
						, 'Save'
						Button
							appearance: 'subtle'
							onClick: @props.onClose
						, 'Cancel'
		},
			div {className: styles.modalBody},
				table {className: styles.table},
					tr {className: styles.tableHeader},
						th {}
						th {}, 'User name'
						th {}, 'Permissions'
						th {}

					_.map @state.acl, (access) =>
						tr {},
							td {className: styles.avatarColumn},
								AvatarItem
									avatar: Avatar {
										src: access.user.picture.mini
										size: 'small'
									}
							td {}, access.user.name
							td {className: styles.selectColumn},
								Select
									styles: selectStyle
									menuPortalTarget: document.body
									spacing: 'compact'
									options: [
										label: 'Write'
										value: 'Write'
									,
										label: 'Read'
										value: 'Read'
									]
									value: {label: access.access.level, value: access.access.level}
									onChange: (option) =>
										newAccessList = _.cloneDeep @state.acl
										accessToUpdate = _.find newAccessList, userID: access.userID
										accessToUpdate.access.level = option.value
										@setState acl: newAccessList
							td {className: styles.cancelColumn},
								div
									className: styles.removeAction
									onClick: =>
										newAccessList = _.reject @state.acl, (user) ->
											user.userID is access.userID
										@setState acl: newAccessList
								,
									Icon {icon: x, size: 16}
					tr {},
						td {className: styles.avatarWrapper}
						td {},
							Query
								query: getProjectMembers
								variables:
									projectSlug: @props.match.params.project
							, ({loading, data}) =>
								if loading
									null
								else
									Select
										styles: selectStyle
										menuPortalTarget: document.body
										spacing: 'compact'
										isSearchable: true
										options: _.reject @props.members, (member) => _.find(@state.acl, {userID: member.id})?
										formatOptionLabel: (option) -> option.name
										getOptionLabel: (option) -> option.name
										getOptionValue: (option) -> option
										placeholder: 'Add contributor'
										onChange: (value) =>
											newAccessList = _.cloneDeep @state.acl
											newAccess =
												user: {...value}
												userID: value.id
												access:
													level: 'Read'
											newAccessList.push newAccess
											@setState acl: newAccessList
										value: null




