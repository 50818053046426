"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
Object.defineProperty(exports, "PermissionScopes", {
  enumerable: true,
  get: function get() {
    return _permissionScopes.default;
  }
});
Object.defineProperty(exports, "BasePermissions", {
  enumerable: true,
  get: function get() {
    return _basePermissions.default;
  }
});

var _lodash = _interopRequireDefault(require("lodash"));

var _ability = require("@casl/ability");

var _permissionScopes = _interopRequireDefault(require("./permissionScopes"));

var _basePermissions = _interopRequireDefault(require("./basePermissions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.3.2
var unresolveUser;

unresolveUser = function unresolveUser(user) {
  return {
    _id: user.id,
    roles: user.globalRoles,
    projects: _lodash.default.map(user.projectsPermissions, function (permission) {
      return _objectSpread({}, permission, {
        acl: permission.access
      });
    })
  };
};

function _default(user) {
  var resolved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var basePermissions, i, j, k, l, len, len1, len2, len3, len4, len5, m, n, permission, permissionScopes, permissions, project, ref, ref1, ref2, ref3, ref4, role, rules, scope;

  if (resolved) {
    user = unresolveUser(user);
  }

  permissionScopes = (0, _permissionScopes.default)(resolved);
  basePermissions = (0, _basePermissions.default)(user, resolved);
  permissions = []; // system defaults

  permissions = _lodash.default.concat(permissions, basePermissions); // user roles

  if (user.roles != null) {
    ref = user.roles;

    for (i = 0, len = ref.length; i < len; i++) {
      role = ref[i];
      permission = {
        scopes: role.scopes
      };
      permissions.push(permission);
    }
  } // NOTE: Probably to be moved from here
  // user project roles


  if (user.projects != null) {
    ref1 = user.projects;

    for (j = 0, len1 = ref1.length; j < len1; j++) {
      project = ref1[j];

      if (project.roles != null) {
        ref2 = project.roles;

        for (k = 0, len2 = ref2.length; k < len2; k++) {
          role = ref2[k];
          permissions.push({
            scopes: role.scopes,
            conditions: {
              projectID: project.projectID
            }
          });
        }
      }
    }
  } // user ACL


  for (scope in permissionScopes) {
    permissions.push({
      scopes: [scope],
      conditions: resolved ? {
        'acl.userID': user._id,
        'acl.access.scopes': scope
      } : {
        'acl.userID': user._id,
        'acl.scopes': scope
      }
    });
  } // NOTE: Probably to be moved from here
  // user project ACLs


  if (user.projects != null) {
    ref3 = user.projects;

    for (l = 0, len3 = ref3.length; l < len3; l++) {
      project = ref3[l];

      if (project.acl != null) {
        permissions.push({
          scopes: project.acl.scopes,
          conditions: {
            projectID: project.projectID
          }
        });
      }
    }
  }

  rules = [];

  for (m = 0, len4 = permissions.length; m < len4; m++) {
    permission = permissions[m];
    ref4 = permission.scopes;

    for (n = 0, len5 = ref4.length; n < len5; n++) {
      scope = ref4[n];

      if (permission.conditions == null) {
        rules = _lodash.default.concat(rules, permissionScopes[scope]);
      } else {
        rules = _lodash.default.concat(rules, _lodash.default.map(permissionScopes[scope], function (rule) {
          return _objectSpread({}, rule, {
            conditions: _objectSpread({}, rule.conditions, permission.conditions)
          });
        }));
      }
    }
  }

  if (resolved) {
    return new _ability.Ability(rules, {
      subjectName: function subjectName(subject) {
        if (_lodash.default.isString(subject)) {
          return subject;
        } else {
          return subject.__typename;
        }
      }
    });
  } else {
    return new _ability.Ability(rules);
  }
}

;