import gql from 'graphql-tag'

export ListUsers = gql """
	query($projectSlug: String!) {
		me { id, name }
		project(slug: $projectSlug) {
			id
			members { ...on Person { id, name  } }
		}
	}
"""
