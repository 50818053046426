export default
	global:
		module: 'eOffice'
		overview: 'Home'
		documents: 'Inbox'
		archive: 'Archive'
	overview:
		assigned: 'Assigned to me'
		urgent: 'Urgent'
		toReview: 'To review'
		toScan: 'To scan'
		toSpecify: 'To specify'
	details:
		dates: 'Dates'
		identifiers: 'Identifiers'
		entities: 'Involved entities'
	registration:
		basic: 'Basic info'
		category: 'Category'
		details: 'Details'
		review: 'Review'
	props:
		form: 'Type'
		topic: 'Subject'
		receivalDate: 'Received on'
		source: 'Source'
		spv: 'Company'
		status: 'Status'
		assignee: 'Assigned to'
		hasScan: 'Scan'
		location: 'Location'
		buildings: 'Buildings'
		phases: 'Phases'
		mainContract: 'Main contract'
		modifiedAt: 'Modified at'
		createdAt: 'Created at'
		creationDate: 'Creation date'
		approvedAt: 'Approved at'
		approvedBy: 'Approved by'
		validationDate: 'Validation date'
		expirationDate: 'Expiration date'
		originalDocument: 'Duplicated from'
		number: 'Number'
		caseNumber: 'Case number'
		landRegisterNumber: 'Land register number'
		NIPNumber: 'NIP number'
		KRSNumber: 'KRS number'
		VatNumber: 'Vat number'
		REGONNumber: 'REGON number'
		parcelNumber: 'Parcel number'
		precinctNumber: 'Precinct number'
		recipient: 'Recipient'
		participants: 'Participants'
		notes: 'Notes'
		path: 'Path'
	statuses:
		Categorized: 'To specify'
		Filled: 'To review'
		Filled_noScan: 'To scan'
		Reviewed: 'To approve'
		Approved: 'Approved'
