###
Revenue HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import {
	Route as _Route
	Switch as _Switch
	Link as _Link
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Views
OverviewView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-overview-view" */'../ProjectRevenueOverview')`
	loading: -> div {}

StackingPlanView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-stackingplan-view" */'../ProjectRevenueStackingPlan')`
	loading: -> div {}

TenantMixView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-tenantmix-view" */'../ProjectRevenueTenantMix')`
	loading: -> div {}

TenantMixListView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-tenantmix-view" */'../ProjectRevenueTenantMixList')`
	loading: -> div {}

ProgressMatrixView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-progressmatrix-view" */'../ProjectRevenueProgress')`
	loading: -> div {}

PipelineView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-pipeline-view" */'../ProjectRevenuePipeline')`
	loading: -> div {}

OpportunityView = Loadable
	loader: -> `import(/* webpackChunkName: "revenue-opportunity-view" */'../ProjectRevenueOpportunity')`
	loading: -> div {}

# Data
import { RootQuery } from './data'
# Styles
import styles from './index.styl'
import {
	externalLink
} from 'react-icons-kit/feather'


export default class ProjectRevenue extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				RouterTabs
					className: styles.tabs
					location: @props.location
					header: ApplicationHeader {
						loading: loading
						project: if !loading then data.project.name
						application: 'Revenue'
					}
					tabs: [
							label: 'Overview'
							content: div {className: styles.tabContent},
								React.createElement OverviewView, @props
							href: "/projects/#{@props.match.params.project}/revenue"
							exact: true
							id: 1
							redirect: "/projects/#{@props.match.params.project}/revenue/opportunities"
						,
							label: 'Opportunities'
							content: div {className: styles.tabContent},
								React.createElement OpportunityView, @props
							href: "/projects/#{@props.match.params.project}/revenue/opportunities"
							exact: false
							id: 6
						,
							label: 'Leasing status'
							content: div {className: styles.tabContent},
								React.createElement ProgressMatrixView, @props
							href: "/projects/#{@props.match.params.project}/revenue/progress"
							exact: true
							id: 4
						,
							label: 'Leasing offers'
							content: div {className: styles.tabContent},
								React.createElement StackingPlanView, @props
							href: "/projects/#{@props.match.params.project}/revenue/plan"
							exact: false
							id: 2
						,
							label: 'Tenant mixes'
							content: div {className: styles.tabContent},
								# React.createElement TenantMixView, @props
								Switch {},
									Route
										path: '/projects/:project/revenue/mix/:variant'
										render: (props) -> React.createElement TenantMixView, { ...props }
									Route
										path: '/projects/:project/revenue/mix'
										render: (props) -> React.createElement TenantMixListView, { ...props }
							href: "/projects/#{@props.match.params.project}/revenue/mix"
							exact: false
							id: 3
						# ,
						# 	label: 'Pipeline'
						# 	content: div {className: styles.tabContent},
						# 		React.createElement PipelineView, @props
						# 	href: "/projects/#{@props.match.params.project}/revenue/pipeline"
						# 	exact: true
						# 	id: 5
					]
