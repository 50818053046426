import gql from 'graphql-tag'
import {
	Project
	Visit
	Observation
} from './query.graphql'

export RootQuery = gql """
	query($projectSlug: String!){
		project(slug: $projectSlug) @client {
			#{Project}
		}
	}
"""

export VisitsQuery = gql """
	query getVisits {
		visits (
			pagination: { limit: 0 }
			filters: {
				variant: Offices
			}
		) {
			#{Visit}
			ehsObservations {
				#{Observation}
			}
		}
	}
"""
