###
document reassign modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTitle as _ModalTitle
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter
ModalTitle = React.createFactory _ModalTitle
ModalTransition = React.createFactory _ModalTransition

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import {Field as _Field} from '@atlaskit/form'
Field = React.createFactory _Field

import _FieldTextArea from '@atlaskit/field-text-area'
FieldTextArea = React.createFactory _FieldTextArea

import _Select , {components} from '@atlaskit/select'
Select = React.createFactory _Select

# Data
import { ListUsers } from './data'

# Styles
import styles from './index.styl'

export default class DocumentReassignModal extends React.Component
	constructor: (props) ->
		super props
		@state =
			comment: ''
			currentAssignee: if @props.currentAssignee? then @props.currentAssignee else null

	handleAssigneeChange: (assignee) =>
		@setState
			currentAssignee: assignee

	handleCommentChange: (comment) =>
		@setState
			comment: comment

	handleReassignButtonClick: () =>
		if !_.isEmpty @state.currentAssignee
			@props.onConfirm(@state.currentAssignee, @state.comment)
			@props.onDone()
			@setState
				comment: ''

	MenuList: (props) =>
		div {},
			if props.selectProps.me?
				Button
					shouldFitContainer: true
					className: styles.selectMeButton
					spacing: 'compact'
					onClick: () =>
						props.setValue()
						@handleAssigneeChange props.selectProps.me
				, "Me (#{props.selectProps.me.name})"
			components.MenuList {...props},
				props.children

	render: ->
		selectStyle =
			menuPortal:
				(base) ->
					{
						...base
						zIndex: 9999
					}
		ModalTransition {},
			do => if @props.active
				Query
					query: 	ListUsers
					variables:
						projectSlug: @props.match.params.project
				, ({ loading, data, error}) =>
					if loading or error
						return Spinner {}
					else
						availableOptions = data.project.members
						if @state.currentAssignee?
							availableOptions = _.reject availableOptions, @state.currentAssignee
						if _.includes availableOptions, data.me
							availableOptions = _.reject availableOptions, data.me
							me = data.me
						Modal {
							width: 'small'
							height: 200
							shouldCloseOnEscapePress: false
							shouldCloseOnOverlayClick: false
							onClose: @props.onDone
							header: ({showKeyline}) =>
								ModalHeader {showKeyline: showKeyline},
									ModalTitle {}, 'Reassign document'
							footer: ({showKeyline, onClose}) =>
								ModalFooter {showKeyline: showKeyline},
									div {className: styles.footer},
										Button
											className: styles.button
											appearance: 'primary'
											onClick: () => @handleReassignButtonClick()
										, 'Reassign'
										Button
											className: styles.button
											appearance: 'subtle'
											onClick: =>	@props.onDone()
										, 'Cancel'
						},
							div {className: cnames styles.base},
								Field {label: 'Assignee'},
									Select
										menuPortalTarget: document.body
										styles: selectStyle
										isSearchable: true
										# placeholder: 'ssss'
										me: me
										options: _.orderBy availableOptions, 'name'
										formatOptionLabel: (option) -> option.name
										getOptionLabel: (option) -> option.name
										getOptionValue: (option) -> option
										onChange: (value) => @handleAssigneeChange value
										value: @state.currentAssignee
										components: {@MenuList}

								# Field {label: 'Notes'},
								# 	FieldTextArea
								# 		onChange: (event) => @handleCommentChange(event.target.value)
								# 		value: @state.comment
								# 		placeholder: 'Insert comment...'
								# 		shouldFitContainer: true
