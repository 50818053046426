###
Easily switch between projects with dropdown and projects as an options
FIXME: Items in dropdown should feature avatar of projects
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
# Renderable
import { div, img, p, span} from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _DropdownMenu, { DropdownItem as _DropdownItem} from '@atlaskit/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu
DropdownItem = React.createFactory _DropdownItem

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Styles
import styles from './index.styl'
import {
	chevronDown
	chevronUp
} from 'react-icons-kit/feather'

export class ProjectItem extends React.Component
	@propTypes:
		interactive: PropTypes.bool
		after: PropTypes.func
		navigate: PropTypes.func
		project: PropTypes.shape
			photo: PropTypes.shape
				mini: PropTypes.string
			id: PropTypes.string
			slug: PropTypes.string
			name: PropTypes.string
			city: PropTypes.string
			country: PropTypes.string
	render: ->
		div {
			className: [
				styles.projectItem
				if @props.interactive then styles.interactive
			].join ' '
		},
			Avatar
				component: ({children, className}) ->
					div {
						style:
							pointerEvents: 'none'
						className: className
					}, children
				src: @props.project.photo.mini
				appearance: 'square'
			div {className: styles.description},
				p {className: styles.text}, "#{@props.project.name}"
				p {className: styles.subText}, "#{@props.project.city}, #{@props.project.country}"
			do =>
				if @props.after?
					div {className: styles.after},
						@props.after()

_ProjectItem = React.createFactory ProjectItem

export default class ProjectSelectionDropdown extends React.Component
	constructor: (props) ->
		super props
		@data = {}
	render: ->
		Query
			query: gql '
				query{
					projects {
						id
						name
						city
						country
						slug
						photo {
							mini
						}
					}
				}
			'
		, ({data, loading}) =>
			#FIXME: walkaround for apollo errors
			if data? and !_.isEmpty data
				@data = data
			DropdownMenu
				shouldFitContainer: true
				isLoading: loading
				trigger: do =>
					if !loading
						project = _.find @data.projects, slug: @props.project
						_ProjectItem {
							project
							interactive: true
							after: ->
								Icon icon: chevronDown, size: 28
						}
			,
				otherProjects = _.reject(@data.projects, slug: @props.project)
				if _.isEmpty otherProjects
					span {className: styles.emptyList}, 'no other available projects'
				else
					_.map otherProjects, (project) =>
						DropdownItem {
							key: project.id
							onClick: (event) =>
								@props.navigate("/projects/#{project.slug}") event
						},
							_ProjectItem
								project: project
