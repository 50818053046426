"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

// Generated by CoffeeScript 2.3.2
function _default(user) {
  var resolved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return [{
    // Users self
    scopes: ['UserRead'],
    conditions: resolved ? {
      'id': user._id
    } : {
      '_id': user._id
    }
  }, {
    // Organizations public
    scopes: ['OrganizationCreate', 'OrganizationRead']
  }, {
    // Legal Document assignee
    scopes: ['LegalDocumentReadDraft', 'LegalDocumentReadCommited', 'LegalDocumentPrintQRCode', 'LegalDocumentPrintQRCod', 'LegalDocumentUpdateScan', 'LegalDocumentUpdateDetails', 'LegalDocumentUpdateAssignee'],
    conditions: resolved ? {
      // TODO global property rename
      'asignee.id': user._id
    } : {
      'assigneeData.id': user._id
    }
  }, {
    // Legal Scan creator
    scopes: ['LegalScanReadUnmatched'],
    conditions: resolved ? {
      'createdBy.id': user._id
    } : {
      'createdBy.id': user._id
    }
  }];
}

;