###
Global entrypoint to S360 platofrm
TODO: Replace image with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'


# Renderable
import {
	Redirect as _Redirect
} from 'react-router-dom'
Redirect = React.createFactory _Redirect

import { div, p, img } from 'react-dom-factories'

import _PageHeader from '@atlaskit/page-header'
PageHeader = React.createFactory _PageHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _ProjectCard from '@bevy/project-card'
ProjectCard = React.createFactory _ProjectCard

# Data
import { RootQuery } from './data'

# Styles
import styles from './index.styl'

export default class Dashboard extends React.Component
	render: ->
		div {className: cnames styles.base, @props.className},

			# temp redirect to first project
			Query
				query: RootQuery
			, ({data, loading}) =>
				if loading
					Spinner {}
				else
					if data.projects?
						Redirect to: "/projects/#{data.projects[0].slug}"
					else
						Fragment {},
							PageHeader {}, 'Home'
							img {src: require('assets/Dashboard1.png'), className: 'img-responsive'}
							# Query
							# 	query: RootQuery
							# , ({data, loading}) =>
							# 	if loading
							# 		Spinner {}
							# 	else
							# 		div {className: styles.ProjectCardsGroup},
							# 			do =>
							# 				_.map data.projects, (project) =>
							# 					div
							# 						className: styles.projectCard
							# 						key: project.id
							# 					,
							# 						ProjectCard
							# 							onClick: => @props.history.push "/projects/#{project.slug}"
							# 							thumbnail: project.photo.mini
							# 							name: project.name
							# 							country: project.country
							# 							city: project.city
							# 							# startDate: project.baseline.timeline.start
							# 							# endDate: project.baseline.timeline.end
							img {src: require('assets/Dashboard2.png'), className: 'img-responsive'}
