###
Info Viewer
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'

# Renderable
import { div, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment
import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import _Select , {components} from '@atlaskit/select'
Select = React.createFactory _Select

import { CheckboxSelect as _CheckboxSelect } from '@atlaskit/select'
CheckboxSelect = React.createFactory _CheckboxSelect

import _Form, { Field as _Field } from '@atlaskit/form'
Form = React.createFactory _Form
Field = React.createFactory _Field

import {DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import _Button from '@bevy/button'
Button = React.createFactory _Button

import InlineEdit, { SingleLineTextInput as _SingleLineTextInput } from '@atlaskit/inline-edit'
InlineEditC = React.createFactory InlineEdit
SingleLineTextInput = React.createFactory _SingleLineTextInput

import FieldTextArea, { FieldTextAreaStateless } from '@atlaskit/field-text-area'
FieldTextAreaC = React.createFactory FieldTextAreaStateless

# Data
import {
	getDocumentCombinationsFromPath,
	getEntities
	getUsers
} from '../../data'
import trLegal from 'data/local/legal'

# Styles
import styles from './index.styl'


export default class DocumentInfo extends React.Component
	constructor: (props) ->
		super props
		@InlineRef = React.createRef()
		@state =
			editValue: ''
			isEditingDescription: false
			currDescription: ''

	handleDateChange: (newValue, type) =>
		newValue = moment(newValue, 'YYYY-MM-DD').unix()
		@props.onDateUpdate type, newValue

	handleIdentifierChange: (newValue, type) =>
		@props.onIdentifierUpdate type, newValue

	handleDescriptionChange: (newValue) =>
		@props.onDescriptionUpdate newValue

	MenuList: (props) =>
		div {},
			Button
				shouldFitContainer: true
				className: styles.selectAllButton
				spacing: 'compact'
				onClick: => @props.onBuildingsUpdate props.options
			, 'Select all'
			components.MenuList props, props.children

	renderDatesColumn: (options) =>
		options = _.orderBy options, 'isRequired', 'desc'
		options = _.reject options, type: 'ReceivalDate'
		Fragment {},
			div {className: styles.header}, trLegal.details.dates
			FormatterConsumer {}, (format) =>
				_.map options, (date, index) =>
					filled =  _.find @props.document.dates, (filledDate) ->
						filledDate.type is date.type
					div {className: styles.item, key: index},
						div {className: styles.title},
							_.capitalize _.lowerCase date.type
							if date.isRequired and !@props.isArchived
								span {className: styles.required}, ' *'
						DatePicker
							isDisabled: @props.isArchived
							# isOpen: @props.isArchived
							icon: null
							onChange: (value) => @handleDateChange(value, if filled? then filled.type else date.type)
							value: if filled?.value? then moment(format.date(filled.value), 'DD/MM/YYYY')

	renderIdentifiersColumn: (options) =>
		options = _.orderBy options, 'isRequired', 'desc'
		Fragment {},
			div {className: styles.header}, trLegal.details.identifiers
			_.map options, (identifier, index) =>
				filled =  _.find @props.document.identifiers, (filledIdentifier) ->
					filledIdentifier.type is identifier.type
				div {className: styles.item, key: index},
					div {className: styles.title},
						Fragment {},
							_.capitalize _.lowerCase identifier.type
							if identifier.isRequired and !@props.isArchived
								span {className: styles.required}, ' *'
					div {className: styles.paddingLeft},
						InlineEditC
							isLabelHidden: true
							readView: if filled? and filled.value? then filled.value else div {className: styles.placeholder}, 'e.g. ABC123'
							editView: if !@props.isArchived
								SingleLineTextInput
									isEditing: true
									isInitiallySelected: true
									value:
										if !_.isEmpty @state.editValue
											@state.editValue
										else if filled?
											filled.value
									onChange: (e) => @setState editValue: e.target.value
							onConfirm: (e) =>
								if !_.isEmpty @state.editValue
									@handleIdentifierChange(@state.editValue, if filled? then filled.type else identifier.type)
								@setState editValue: null
							onCancel: (e) => @setState editValue: null

	renderEntitiesColumn: (options) =>
		options = _.orderBy options, 'isRequired', 'desc'
		Fragment {},
			div {className: styles.header}, trLegal.details.entities
			Fragment {}, do =>
				_.map options, (entity) =>
					if entity.type is 'Participants'
						Query { key: entity.type, query: getEntities }, ({ loading, data, error }) =>
							if loading or error? then null
							else
								div {className: styles.item, key: entity.type},
									div {className: styles.title},
										_.capitalize _.lowerCase entity.type
										if entity.isRequired and !@props.isArchived
											span {className: styles.required}, ' *'
									Select
										# className: styles.formItem
										isDisabled: @props.isArchived
										menuPortalTarget: document.body
										styles: menuPortal: (base) -> { ...base, zIndex: 9999 }
										isSearchable: true
										isMulti: entity.isMulti
										options: _.orderBy data.organizations, 'name'
										formatOptionLabel: (option) -> option.name
										getOptionLabel: (option) -> option.name
										getOptionValue: (option) -> option.name
										onChange: (value) => if entity.isMulti then @props.onParticipantsUpdate value else @props.onParticipantsUpdate [value]
										value: if entity.isMulti then @props.document.entities.participants else _.first @props.document.entities.participants
					else if entity.type is 'Recipient'
						Query { key: entity.type, query: getUsers }, ({ loading, data, error }) =>
							if loading or error? then null
							else
								div {className: styles.item, key: entity.type},
									div {className: styles.title},
										_.capitalize _.lowerCase entity.type
										if entity.isRequired  and !@props.isArchived then span {className: styles.required}, ' *'
									Select
										menuPortalTarget: document.body
										isDisabled: @props.isArchived
										styles: menuPortal: (base) -> { ...base, zIndex: 9999 }
										isSearchable: true
										options: _.orderBy data.users, 'name'
										formatOptionLabel: (option) -> option.name
										getOptionLabel: (option) -> option.name
										getOptionValue: (option) -> option
										onChange: (value) => @props.onRecipientUpdate value
										value: @props.document.entities.recipient


	renderDescriptionColumn: (buildingRequired) =>
		Fragment {},
			if buildingRequired?
				Fragment {},
					div {className: styles.header}, trLegal.props.buildings
					do =>
						options = [
							{label: 'Building A (Phase 1)', value: 'A'},
							{label: 'Building B (Phase 2)', value: 'B'},
							{label: 'Building C (Phase 2)', value: 'C'},
						]
						CheckboxSelect
							value: _.map @props.document.buildings, (item) => _.find options, value: item
							isDisabled: @props.isArchived
							placeholder: 'Select...'
							onChange: @props.onBuildingsUpdate
							options: options
							components: {@MenuList}

			if @props.document.description?
				Fragment {},
					div {className: styles.header}, trLegal.props.notes
					if @state.isEditingDescription
						Fragment {},
							div {className: styles.fieldText},
								FieldTextAreaC
									isLabelHidden: true
									autoFocus: true
									compact: false
									shouldFitContainer: true
									minimumRows: 7
									value:
										if !_.isEmpty @state.currDescription
											@state.currDescription
										else if @props.document.description?
											@props.document.description
									onBlur: () =>
										if !_.isEmpty @state.currDescription
											@handleDescriptionChange(@state.currDescription)
											@setState
												isEditingDescription: false
												currDescription: ''
									onChange: (e) => @setState currDescription: e.target.value
					else
						div
							className: styles.note
							onClick: () =>
								if !@props.isArchived
									@setState isEditingDescription: true
						,
							@props.document.description


	render: ->
		Query
			query: getDocumentCombinationsFromPath
			variables:
				pathID: @props.document.path.id
		, ({ loading, data, error}) =>
			if loading or error
				return div {}, 'Loading...'
			else
				combination = _.find data.legalDocumentPath.combinations, {topicID: @props.document.topic.id, formID: @props.document.form.id}
				div {className: styles.base},
					div {className: styles.content},
						div {className: styles.column}, @renderDatesColumn(combination.dates)
						div {className: styles.column}, @renderIdentifiersColumn(combination.identifiers)
						div {className: styles.column}, @renderEntitiesColumn(combination.entities)
						div {className: styles.column}, @renderDescriptionColumn(combination.building)

					div {className: styles.footer},
						div {className: styles.title}, "#{trLegal.props.path}:"
						div {className: styles.value}, @props.document.path.full
