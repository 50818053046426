import gql from 'graphql-tag'

export ListDocumentScans = gql """
	query {
		legalDocumentScans(isMapped: false) {
			id
			timestamp
			scan {
				original
			}
		}
	}
"""
