###
Component to display document header
###

# Libs
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'
import DataAttribute from '@bevy/data-attribute'

# Components
import { div, button, span, p } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import _Avatar, {AvatarItem as _AvatarItem} from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar
AvatarItem = React.createFactory _AvatarItem

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import _InlineEdit from '@bevy/inline-edit'
InlineEdit = React.createFactory _InlineEdit

# import _Button from '@bevy/button'
# Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

#Styles
import styles from './index.styl'
import {
	refreshCw
	check
	alertCircle
	# arrowLeft
} from 'react-icons-kit/feather'

export default class DocumentHeader extends React.Component
	@propTypes =
		compact: PropTypes.bool
		mini: PropTypes.bool
		title: PropTypes.node.isRequired
		description: PropTypes.string
		author: PropTypes.shape
			name: PropTypes.string
			image: PropTypes.string
		lastUpdate: PropTypes.number
		lastUpdateShort: PropTypes.bool
		status: PropTypes.oneOfType [
			PropTypes.string
			PropTypes.shape
				name: PropTypes.string.isRequired
				appearance: PropTypes.oneOf [
					'default'
					'inprogress'
					'moved'
					'new'
					'removed'
					'success'
				]
				isBold: PropTypes.bool
			]
		state: PropTypes.node
		side: PropTypes.node
		sync: PropTypes.oneOf [
				'Saved'
				'Syncing'
				'Error'
			]
		actions: PropTypes.node
	@defaultProps:
		compact: false
		mini: false
		lastUpdateShort: false
		# edit: false
		# backArrow: true

	render: ->
		div {className: cnames [
			'bevy-document-header'
			if @props.compact then styles.compact
			if @props.mini then styles.mini
			@props.className
			styles.base
			if @props.edit then styles.edit
		]},
			div {className: cnames [
				styles.main
			]},
				div {className: cnames styles.documentInfo, 'bevy-document-header-document-info'},
					do => if @props.action?
						div {className: styles.action}, @props.action
					div {
						className: styles.titleContainer
						style: if @props.titleAtTheTop? then flexDirection: 'column-reverse'},
						span {className: cnames styles.type, 'bevy-document-header-document-info-type'}, @props.type
						span {className: cnames styles.title, 'bevy-document-header-document-info-title'},
							if @props.onTitleChange?
								InlineEdit {
									text: @props.title
									onChange: @props.onTitleChange
									placeholderText: @props.placeholderText
									placeholderTooltipContent: @props.placeholderTooltipContent
								}
							else
								@props.title
					div {className: cnames(styles.sidebar, 'bevy-document-header-sidebar')},
						div {className: styles.status},
							if @props.side?
								@props.side
							do =>
								if @props.status?
									div {className: styles.lozenge},
											Lozenge
												appearance: if @props.status.appearance? then @props.status.appearance else 'default'
												isBold: @props.status.isBold
											,
												if @props.status.name? then @props.status.name else @props.status

							if @props.lastUpdate?
								Fragment {},
									div {className: cnames styles.modificationStatus, 'bevy-document-header-modificationStatus'},
										span {}, do =>
											if @props.lastUpdateShort is true
												weeks = moment().diff(moment.unix(@props.lastUpdate), 'weeks', true)
												days = moment().diff(moment.unix(@props.lastUpdate), 'days', true)
												if days < 1
													'today'
												else if weeks < 1
													"#{_.floor(days)} d. ago"
												else
													"#{_.floor(weeks)} w. ago"
											else
												"Last modified #{moment.unix(@props.lastUpdate).fromNow()}"
										do =>
											if @props.author?.name?
												Fragment {},
													span {}, ' by '
													span {className: styles.author}, @props.author.name
						do =>
							if @props.actions?
								div {className: cnames [ styles.actions, 'document-header-actions' ]},
									@props.actions
			# 			if @props.status?
			# 				div {className: styles.status},
			# 					div {className: styles.lozenge},
			# 						Lozenge
			# 							appearance: if @props.status.appearance? then @props.status.appearance else 'default'
			# 							isBold: @props.status.isBold
			# 						,
			# 							if @props.status.name? then @props.status.name else @props.status

			# 		if @props.description?
			# 			p {className: cnames styles.description}, @props.description

			# 		if @props.tags?
			# 			div {className: styles.tags},
			# 				_.map @props.tags, (tag) ->
			# 					div {className: styles.tag},
			# 						Lozenge
			# 							appearance: if tag.appearance? then tag.appearance else 'default'
			# 						,
			# 							if tag.name? then tag.name else tag

			# 		div {className: cnames styles.authorContainer, styles.description},
			# 			div {className: styles.author},
			# 				if @props.lastUpdate?
			# 						span {
			# 							'data-test-category': DataAttribute 'last-update'
			# 						}, "Last modified #{moment.unix(@props.lastUpdate).fromNow()}", if @props.author?.name? then " by #{@props.author.name}"
			# 			if @props.sync?
			# 				status =
			# 					name: @props.sync
			# 				switch @props.sync
			# 					when 'Syncing'
			# 						status.icon = refreshCw
			# 						status.className = styles.syncing
			# 					when 'Saved'
			# 						status.icon = check
			# 						status.className = styles.saved
			# 					when 'Error'
			# 						status.icon = alertCircle
			# 						status.className = styles.error
			# 				div {className: cnames [ styles.sync, status.className ]},
			# 					Tooltip {content: status.name},
			# 						Icon {icon: status.icon, size: 12}

			# div {className: cnames [ styles.sub ]},
			# 	if @props.state?
			# 		div {className: cnames [ styles.state, 'document-header-state' ]},
			# 			@props.state
			# 	if @props.actions?
			# 		div {className: cnames [ styles.actions, 'document-header-actions' ]},
			# 			@props.actions
