###
Revenue HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Views
OverviewView = Loadable
	loader: -> `import(/* webpackChunkName: "safety-overview-view" */'../ProjectSafetyOverview')`
	loading: -> div {}

VisitsView = Loadable
	loader: -> `import(/* webpackChunkName: "safety-visits-view" */'../ProjectSafetyVisits')`
	loading: -> div {}

import ObservationsView from '../ProjectSafetyObservations'
# ObservationsView = Loadable
# 	loader: -> `import(/* webpackChunkName: "safety-observations-view" */'../ProjectSafetyObservations')`
# 	loading: -> div {}


# Data
import { RootQuery } from './data'
# Styles
import styles from './index.styl'


export default class SafetyView extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				if !_.isEmpty data
					@data = data
				RouterTabs
					className: styles.tabs
					location: @props.location
					header: ApplicationHeader {
						loading: loading
						project: if !loading then @data.project.name
						application: 'Safety'
					}
					tabs: [
							label: 'Overview'
							content: div {className: styles.tabContent},
								React.createElement OverviewView, @props
							href: "/projects/#{@props.match.params.project}/safety"
							exact: true
							id: 1
						,
							label: 'Safety rounds'
							content: div {className: styles.tabContent},
								React.createElement VisitsView, @props
							href: "/projects/#{@props.match.params.project}/safety/visits"
							catchAll: true
							id: 4
						,
							label: 'Safety issues'
							content: div {className: styles.tabContent},
								React.createElement ObservationsView, @props
							href: "/projects/#{@props.match.params.project}/safety/observations"
							exact: false
							id: 5
					]
