###
document mapping modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import __PDFPreview from '@bevy/pdf-preview'
PDFPreview = React.createFactory __PDFPreview

import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTitle as _ModalTitle
	ModalTransition as _ModalTransition
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter
ModalTitle = React.createFactory _ModalTitle
ModalTransition = React.createFactory _ModalTransition

import _ScanCard from './components/ScanCard'
ScanCard = React.createFactory _ScanCard

# Data
import {
	ListDocumentScans
} from './data'

# Styles
import styles from './index.styl'

export default class LinkDocumentScan extends React.Component
	constructor: (props) ->
		super props
		@state =
			selectedScan: 0

	render: ->
		ModalTransition {},
			do => if @props.active
				Query
					query: ListDocumentScans
				, ({ loading, data, error}) =>
					if loading
						return Spinner {}
					if error
						return Spinner {}
					else
						Modal {
							width: 'large'
							shouldCloseOnEscapePress: false
							shouldCloseOnOverlayClick: false
							onClose: @props.onDone
							header: ({showKeyline}) =>
								ModalHeader {showKeyline: showKeyline},
									ModalTitle {}, 'Select scan of this document'
							footer: ({showKeyline, onClose}) =>
								ModalFooter {showKeyline: showKeyline},
									div {className: styles.actions},
										Button
											appearance:'primary'
											isDisabled: _.isEmpty data.legalDocumentScans
											onClick: =>
												@props.linkScan
													variables:
														documentID: @props.documentID
														scanID: orderedDocs[@state.selectedScan].id
												@props.onDone()
										, 'Link'
										Button
											appearance: 'subtle'
											onClick: =>	@props.onDone()
										, 'Cancel'
						},
							if _.isEmpty data.legalDocumentScans
								div {className: styles.placeholder}, 'No scans available.'
							else
								orderedDocs = (_.orderBy data.legalDocumentScans, ['timestamp'], ['desc'])
								div {
									className: cnames styles.content
									style: if @props.inverted then flexDirection: 'row-reverse'
								},
										div { className: cnames styles.scansGallery, if @props.inverted then styles.borderLeft else styles.borderRight },
											if data.legalDocumentScans?
												_.map orderedDocs, (scan, idx) =>
													ScanCard
														className: cnames styles.scanCard, if @state.selectedScan is idx then styles.selected
														key: idx
														preview: if !@props.inverted then scan.scan.original
														title: moment.unix(scan.timestamp).fromNow()
														description: "1 page"
														onClick: () =>
															@setState selectedScan: idx
											else
												'No previews'
										div { className: cnames styles.fullPreview},
											PDFPreview {
												scale: 0.7
												scan: if data.legalDocumentScans?
													orderedDocs[@state.selectedScan].scan.original
												}
