###
Key performance indicator visualisation with support for delta changes and comparision with different value
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import { div, p, ul, li, small, h1, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'
import {
	trendingDown
	trendingUp
	minus

	arrowUpRight
	arrowDownRight
	alertTriangle
} from 'react-icons-kit/feather'


export default class KPI extends React.Component
	@propTypes:
		className: PropTypes.string
		description: PropTypes.node
		before: PropTypes.node
		after: PropTypes.node
		tooltip: PropTypes.node
		difference: PropTypes.shape
			direction: PropTypes.oneOf [
				'up'
				'down'
			]
			positive: PropTypes.bool
			value: PropTypes.node
		important: PropTypes.bool
		warning: PropTypes.bool
		warningDescription: PropTypes.node

	render: ->
		content =
			div {
				className: cnames [
					styles.kpi
					@props.className
					if @props.important then styles.important
					if @props.warning then styles.warning
				]
			},
				div {
					className: cnames [styles.descriptionContent, 'kpi-descriptor']
				},
					@props.description
					if @props.warning then Icon {className: styles.warningIcon, size: 10, icon: alertTriangle}
				div {className: cnames [styles.valueWrapper, 'kpi-value-container']},
					do =>
						if @props.before
							div {className: cnames [styles.before, 'kpi-value-before']}, @props.before
					div {className: cnames [
						styles.value
						'kpi-value-inner'
						]},
							if !_.isEmpty @props.difference
								div {className: styles.descriptionIndicator},
									span {className: cnames [
										styles.descriptionTrend
										switch @props.difference.positive
											when true then styles.success
											when false then styles.removed
											else
												styles.default
									]},
										Icon size: 15, icon: do =>
											switch @props.difference.direction
												when 'up' then arrowUpRight
												when 'down' then arrowDownRight
												else
													minus
							@props.children
					do =>
						if @props.after
							div {className: cnames [styles.after, 'kpi-value-after']}, @props.after
				if @props.difference?.value?
					div {className: styles.description},
						Fragment {},
							span {}, if @props.difference.direction is 'up' then '+' else ''
							@props.difference.value
				# div {className: styles.description},
				# 	do =>
				# 		if !_.isEmpty @props.difference
				# 			div {className: styles.descriptionIndicator},
				# 				span {className: styles.descriptionTrend},
				# 					Icon size: 12, icon: do =>
				# 						switch @props.difference.direction
				# 							when 'up' then arrowUpRight
				# 							when 'down' then arrowDownRight
				# 							else
				# 								minus
				# 				Lozenge
				# 					appearance: do =>
				# 						switch @props.difference.positive
				# 							when true then 'success'
				# 							when false then 'removed'
				# 							else
				# 								'default'
				# 				, @props.difference.value
		Tooltip {
			content: do =>
				if @props.warning and @props.warningDescription? then @props.warningDescription
				else if @props.tooltip? then @props.tooltip
				else ''
		}, content
