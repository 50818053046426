###
S360 application / platform layout and navigation
###

# Libs
import _ from 'lodash'
import React from 'react'

# Renderable
import {
	Route as _Route
	Switch as _Switch
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch


import AccountsHub from '../pages/Accounts'
import Dashboard from '../pages/Dashboard'
import ProjectsHub from '../pages/Projects'
import ProjectDashboard from '../pages/ProjectDashboard'
import ProjectRevenue from '../pages/ProjectRevenue'
import ProjectCosts from '../pages/ProjectCosts'
import ProjectLegal from '../pages/ProjectLegal'
import ProjectScenarios from '../pages/ProjectScenarios'
import ProjectSafety from '../pages/ProjectSafety'


export default class MainRouter extends React.Component
	render: ->
		pageProps = @props
		Switch {},
			Route
				exact: true
				path: '/'
				render: (props) ->
					React.createElement Dashboard, {...props, ...pageProps}
			Route
				exact: true
				path: '/accounts'
				render: (props) ->
					React.createElement AccountsHub, {...props, ...pageProps}
			Route
				exact: true
				path: '/projects'
				render: (props) ->
					React.createElement ProjectsHub, {...props, ...pageProps}
			Route
				exact: true
				path: '/projects/:project'
				render: (props) ->
					React.createElement ProjectDashboard, {...props, ...pageProps}
			Route
				path: '/projects/:project/revenue'
				render: (props) ->
					React.createElement ProjectRevenue, {...props, ...pageProps}
			Route
				path: '/projects/:project/costs'
				render: (props) ->
					React.createElement ProjectCosts, {...props, ...pageProps}
			Route
				path: '/projects/:project/legal'
				render: (props) ->
					React.createElement ProjectLegal, {...props, ...pageProps}
			Route
				path: '/projects/:project/scenarios'
				render: (props) ->
					React.createElement ProjectScenarios, {...props, ...pageProps}
			Route
				path: '/projects/:project/safety'
				render: (props) ->
					React.createElement ProjectSafety, {...props, ...pageProps}
