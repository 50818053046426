import loki from 'lokijs'
import _ from 'lodash'
import { DocumentIndex } from 'ndx'

database = null

DATABASE_NAME = 'fixtures.db'

init = (APP_VERSION, cb) ->
	version = localStorage.getItem "#{DATABASE_NAME}.version"
	if !version? or version isnt APP_VERSION.toString()
		localStorage.removeItem DATABASE_NAME
		localStorage.setItem "#{DATABASE_NAME}.version", APP_VERSION.toString()
	if database?
		database.__indices__ = {}
		cb null, database
	else
		database = new loki DATABASE_NAME,
			env: 'BROWSER'
			autoload: true
			autoloadCallback: (err) ->
				database.__indices__ = {}
				cb err, database
			autosave: true
			autosaveInterval: 4000
populate = (data, drop = false, indexators) ->
	for key, items of data
		collection = database.getCollection key
		if !collection?
			collection = database.addCollection key,
				unique: ['id']
		collection.clear(removeIndices: true) if drop
		if collection.count() is 0
			console.log 'Inserting document for', key
			collection.insert _.map items, (item) -> _.omit item, ['$loki', 'meta']
		database.__indices__[key] = new DocumentIndex()
	for key, items of data
		if indexators[key]?
			indexators[key] database, database.__indices__[key]
	database.saveDatabase()
export default
	init: init
	populate: populate
