"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Generated by CoffeeScript 2.3.2
// Permission scopes to Rules mapping
// conditions can be either in server or client format (GraphQL model - client data naming thing)
function _default() {
  var _ref;

  var resolved = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    // Users
    User: [{
      subject: 'User',
      actions: 'create'
    }],
    UserCreate: [{
      subject: 'User',
      actions: 'create'
    }],
    UserRead: [{
      subject: 'User',
      actions: 'read'
    }],
    UserUpdate: [{
      subject: 'User',
      actions: 'update'
    }],
    UserDelete: [{
      subject: 'User',
      actions: 'delete'
    }],
    // Organizations
    Organization: [{
      subject: 'Organization',
      actions: 'crud'
    }],
    OrganizationCreate: [{
      subject: 'Organization',
      actions: 'create'
    }],
    OrganizationRead: [{
      subject: 'Organization',
      actions: 'read'
    }],
    OrganizationUpdate: [{
      subject: 'Organization',
      actions: 'update'
    }],
    OrganizationDelete: [{
      subject: 'Organization',
      actions: 'delete'
    }],
    // Projects
    Project: [{
      subject: 'Project',
      actions: 'crud'
    }],
    ProjectCreate: [{
      subject: 'Project',
      actions: 'create'
    }],
    ProjectRead: [{
      subject: 'Project',
      actions: 'read'
    }],
    ProjectReadRevenue: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Revenue'
    }],
    ProjectReadCosts: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Costs'
    }],
    ProjectReadWhatIf: [{
      subject: 'Project',
      actions: 'read',
      fields: 'WhatIf'
    }],
    ProjectReadSafety: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Safety'
    }],
    ProjectReadTQT: [{
      subject: 'Project',
      actions: 'read',
      fields: 'TQT'
    }],
    ProjectReadLegal: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Legal'
    }],
    ProjectReadPersonel: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Personel'
    }],
    ProjectUpdate: [{
      subject: 'Project',
      actions: 'update'
    }],
    ProjectDelete: [{
      subject: 'Project',
      actions: 'delete'
    }],
    // Legal Documents
    LegalDocument: [{
      subject: 'LegalDocument',
      actions: 'crud'
    }],
    LegalDocumentCreate: [{
      subject: 'LegalDocument',
      actions: 'create'
    }],
    LegalDocumentRead: [{
      subject: 'LegalDocument',
      actions: 'read'
    }],
    LegalDocumentReadDraft: [{
      subject: 'LegalDocument',
      actions: 'read',
      conditions: {
        state: 'Draft'
      }
    }],
    LegalDocumentReadCommited: [{
      subject: 'LegalDocument',
      actions: 'read',
      conditions: {
        state: 'Commited'
      }
    }],
    LegalDocumentUpdate: [{
      subject: 'LegalDocument',
      actions: 'update'
    }],
    LegalDocumentUpdateDetails: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'details'
    }],
    LegalDocumentUpdateScan: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'scan'
    }],
    LegalDocumentUpdateAssignee: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'assignee'
    }],
    LegalDocumentUpdateACL: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'acl'
    }],
    LegalDocumentUpdateState: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'state'
    }],
    LegalDocumentPrintQRCode: [(_ref = {
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'scan'
    }, _defineProperty(_ref, "subject", 'LegalScan'), _defineProperty(_ref, "actions", 'create'), _ref)],
    LegalDocumentDelete: [{
      subject: 'LegalDocument',
      actions: 'delete'
    }],
    LegalDocumentDeleteDraft: [{
      subject: 'LegalDocument',
      actions: 'delete',
      conditions: {
        state: 'Draft'
      }
    }],
    LegalDocumentDeleteCommited: [{
      subject: 'LegalDocument',
      actions: 'delete',
      conditions: {
        state: 'Draft'
      }
    }],
    // Legal Scans
    LegalScan: [{
      subject: 'LegalScan',
      actions: 'crud'
    }],
    LegalScanCreate: [{
      subject: 'LegalScan',
      actions: 'create'
    }],
    LegalScanRead: [{
      subject: 'LegalScan',
      actions: 'create'
    }],
    LegalScanReadUnmatched: [{
      subject: 'LegalScan',
      actions: 'create',
      conditions: {
        isMapped: false
      }
    }],
    LegalScanUpdate: [{
      subject: 'LegalScan',
      actions: 'update'
    }],
    LegalScanDelete: [{
      subject: 'LegalScan',
      actions: 'delete'
    }]
  };
}

;