
###
Platform global navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, button } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import {
	chevronRight
	chevronLeft
} from 'react-icons-kit/feather'
import styles from './index.styl'

export default class GlobalNavigation extends React.Component
	@propTypes:
		openDrawer: PropTypes.func
		hasContainerNav: PropTypes.bool

	constructor: (props) ->
		super props
		@state =
			collapseSubNavigation: false

	renderGlobalNavItem: (item, index) ->
		if item.separator
			div {className: styles.separator, key: index}
		else
			navItem =
				div {key: item.key},
					item.component
						className: styles.primaryNavItem
						children: item.icon {}
			if item.tooltip?
				Tooltip {
					content: item.tooltip
					key: item.key
				},
					navItem
			else
				navItem

	render: ->
		div {className: cnames [
			styles.navigationWrapper
			if @state.collapseSubNavigation then styles.collapsedSubNavigation
			if @props.showSubNavigation then styles.withSubNavigation
		]},
			div {className: styles.globalNavigation},
				div {},
					_.map @props.primaryItems, (item, index) => @renderGlobalNavItem(item, index)
				div {},
					_.map @props.secondaryItems, (item, index) => @renderGlobalNavItem(item, index)
			if @props.showSubNavigation
				div
					className: cnames styles.subNavigation, if @state.collapseSubNavigation then styles.collapsed
					onClick: () => if @state.collapseSubNavigation then @setState collapseSubNavigation: false
				,
					div {className: styles.subNavigationEdge, onClick: () => @setState collapseSubNavigation: !@state.collapseSubNavigation},
						div {className: styles.edgeMarker}
						button {
							className: styles.collapseButton
							onClick: () => @setState collapseSubNavigation: !@state.collapseSubNavigation
						}, Icon {icon: if @state.collapseSubNavigation then chevronRight else chevronLeft}
					if not @state.collapseSubNavigation
						@props.subNavigation
			div {className: styles.page},
				@props.children

