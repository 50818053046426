
###
Platform global navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

# Renderable
import { div, button } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _ProjectNavigation from '../ProjectNavigation'
ProjectNavigation = React.createFactory _ProjectNavigation

import _ProjectSelectionDropdown from '../ProjectSelectionDropdown'
ProjectSelectionDropdown = React.createFactory _ProjectSelectionDropdown

import _GlobalNav from '@bevy/global-navigation'
GlobalNav = React.createFactory _GlobalNav

# Styles
import {
	grid
	menu
	bell
	helpCircle
	link
	search
	home
	briefcase
	alignJustify
	fileText
} from 'react-icons-kit/feather'
import styles from './index.styl'

export default class GlobalNavigation extends React.Component
	@propTypes:
		openDrawer: PropTypes.func
		hasContainerNav: PropTypes.bool
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?
	render: ->
		match = matchPath window.location.pathname,
			path: '/projects/:project'
		GlobalNav
			showSubNavigation: @props.hasContainerNav
			primaryItems: [
					key: 'appswitcher'
					component: ({className, children}) =>
						button {
							className: className
							onClick: @props.openDrawer 'appSwitcher'
						}, children
					icon: ->
						Icon icon: grid, size: 20
					tooltip: 'S360'
				,
					key: 'search'
					component: ({className, children}) =>
						button {
							className: className
							# onClick: @props.openDrawer 'search'
						}, children
					icon: ->
						Icon icon: search, size: 20
					tooltip: 'Search'
				,
					separator: true
				,
					#TODO: isSelected
					key: 'home'
					component: ({className, children}) =>
						Link {to: '/'},
							button {
								className: className
							}, children
					icon: ->
						Icon icon: home, size: 20
					isActive: @matchPath '/', true
					tooltip: 'Home'
				# ,
				# 	key: 'projects'
				# 	component: ({className, children}) =>
				# 		Link {to: '/projects'},
				# 			button {
				# 				className: className
				# 			}, children
				# 	isActive: @matchPath '/projects', false
				# 	icon: ->
				# 		Icon icon: alignJustify, size: 20

				# 	tooltip: 'Projects'
				# ,
				# 	key: 'accounts'
				# 	component: ({className, children}) =>
				# 		Link {to: '/accounts'},
				# 			button {
				# 				className: className
				# 			}, children
				# 	icon: ->
				# 		Icon icon: briefcase, size: 20
				# 	isActive: @matchPath '/accounts', false
				# 	tooltip: 'Accounts'
			]
			secondaryItems: [
				separator: true
			,
				key: 'notifications'
				component: ({className, children}) =>
					button {
						className: className
					}, children
				tooltip: 'Notifications'
				icon: ->
					Icon icon: bell, size: 20
			,
				key: 'help'
				component: ({className, children}) =>
					button {
						className: className
					}, children
				tooltip: 'Help'
				icon: ->
					Icon icon: helpCircle, size: 20
			,
				key: 'link'
				component: ({className, children}) =>
					button {
						className: className
					}, children
				tooltip: 'Link'
				icon: ->
					Icon icon: link, size: 20
		]
			subNavigation: do =>
				if @props.hasContainerNav
					div {className: styles.subNavigation},
						div {className: styles.projectSelector}, ProjectSelectionDropdown
							project: match.params.project
							navigate: @props.navigate
						ProjectNavigation
							project: match.params.project
		, @props.children
