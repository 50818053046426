import React from 'react'
import moment from 'moment-mini'

formatterMoney = new Intl.NumberFormat 'pl-PL',
		style: 'decimal'
		minimumFractionDigits: 2
		maximumFractionDigits: 2
formatterInteger = new Intl.NumberFormat 'pl-PL',
	style: 'decimal'
	minimumFractionDigits: 0
	maximumFractionDigits: 0
formatterEur = new Intl.NumberFormat 'pl-PL',
	style: 'currency'
	currency: 'EUR'
	minimumFractionDigits: 2
	maximumFractionDigits: 2
	currencyDisplay: 'symbol'
formatterPln = new Intl.NumberFormat 'pl-PL',
	style: 'currency'
	currency: 'PLN'
	minimumFractionDigits: 2
	maximumFractionDigits: 2
	currencyDisplay: 'symbol'
formatterSqm = new Intl.NumberFormat 'pl-PL',
		style: 'decimal'
		minimumFractionDigits: 2
		maximumFractionDigits: 2
formatterUnits = new Intl.NumberFormat 'pl-PL',
	style: 'decimal'
		minimumFractionDigits: 0
		maximumFractionDigits: 0

context = React.createContext
	eur: (val) ->
		if !val?
			return '–'
		formatterEur.format val
	pln: (val) ->
		if !val?
			return '–'
		formatterPln.format val
	money: (val) ->
		if !val?
			return '–'
		formatterMoney.format val
	integer: (val) ->
		if !val?
			return '–'
		formatterInteger.format val
	sqm: (val) ->
		if !val?
			return '–'
		"#{formatterSqm.format(val)} sqm."
	units: (val) ->
		if !val?
			return '–'
		"#{formatterUnits.format(val)} units"
	percentage: (val) ->
		if !val?
			return '–'
		formatterSqm.format val * 100
	percentageSign: (val) ->
		if !val?
			return '–'
		"#{formatterSqm.format val * 100} %"
	date: (val) ->
		if val instanceof moment
			val.format 'DD/MM/YYYY'
		else
			moment.unix(val).format 'DD/MM/YYYY'
	dateShort: (val) ->
		if val instanceof moment
			val.format 'MM/YYYY'
		else
			moment.unix(val).format 'MM/YYYY'


export default context
export FormatterProvider = React.createFactory context.Provider
export FormatterConsumer = React.createFactory context.Consumer
