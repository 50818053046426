###
Project specific links and tools
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { matchPath} from 'react-router-dom'
import gql from 'graphql-tag'
# Renderable
import { div, p } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import _UserAbilty from 'components/UserAbility'
UserAbility = React.createFactory _UserAbilty

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Styles
import {
	book # summary
	briefcase # leasing
	trendingUp # modelling
	shield # safety
	users # personnel
	checkCircle # quality controll
	dollarSign # costs
	fileText # documents
} from 'react-icons-kit/feather'
import styles from './index.styl'

# Data
import trLegal from 'data/local/legal'

export default class ProjectNavigation extends React.Component
	@propTypes:
		project: PropTypes.string

	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?

	renderItem: (item) ->
		Link {className: styles.itemLink, to: item.href, key: item.key},
			div {className: cnames styles.item, if item.isSelected then styles.selectedItem},
				Icon {className: styles.itemIcon, icon: item.icon, size: 20}
				div {},
					item.text
					if item.description
						div {className: styles.itemDescription}, item.description

	render: ->
		UserAbility {}, (userAbility) =>
			userAbility
			Query
				query: gql """
					query{
						project(slug: "#{@props.project}") {
							id
							acl {userID, access {scopes}}
						}
					}
				"""
			, ({data, loading}) =>
				if data.project?
					userAbility.can 'read', data.project, 'Personel'
					userAbility.can 'read', data.project, 'Revenue'
				sections = [
				# 	key: 'summary'
				# 	items: [
				# 		type: 'item'
				# 		text: 'Summary'
				# 		key: 'summary'
				# 		icon: book
				# 		isSelected: @matchPath '/projects/:project'
				# 		href: "/projects/#{@props.project}"
				# 	]
				# ,
					type: 'group'
					key: 'operations'
					text: 'Operations'
					items: _.compact [
						if !loading and userAbility.can 'read', data.project, 'Revenue'
							type: 'item'
							key: 'revenue'
							text: 'Revenue'
							icon: briefcase
							isSelected: @matchPath '/projects/:project/revenue', false
							href: "/projects/#{@props.project}/revenue"
					,
						if !loading and userAbility.can 'read', data.project, 'Costs'
							type: 'item'
							key: 'costs'
							text: 'Costs'
							icon: dollarSign
							isSelected: @matchPath '/projects/:project/costs', false
							href: "/projects/#{@props.project}/costs"
					,
						if !loading and userAbility.can 'read', data.project, 'WhatIf'
							type: 'item'
							key: 'modelling'
							text: 'What-if'
							icon: trendingUp
							isSelected: @matchPath '/projects/:project/scenarios', false
							href: "/projects/#{@props.project}/scenarios"
					]
				,
					type: 'group'
					key: 'supervision'
					text: 'Supervision'
					items: _.compact [
						if !loading and userAbility.can 'read', data.project, 'Safety'
							type: 'item'
							key: 'ehs'
							text: 'Safety'
							icon: shield
							isSelected: @matchPath '/projects/:project/safety', false
							href: "/projects/#{@props.project}/safety"
					,
						if !loading and userAbility.can 'read', data.project, 'TQT'
							type: 'item'
							key: 'quality'
							text: 'Technical quality'
							icon: checkCircle
							isSelected: @matchPath '/projects/:project/quality', false
							href: "/projects/#{@props.project}/quality"
					]
				,
					type: 'group'
					key: 'misc'
					text: 'Details'
					items: _.compact [
							if !loading and userAbility.can 'read', data.project, 'Legal'
								type: 'item'
								key: 'legal'
								text: trLegal.global.module
								icon: fileText
								isSelected: @matchPath '/projects/:project/legal', false
								href: "/projects/#{@props.project}/legal"
						# NOTE: will be used once we'll implement page
						# ,
						# 	if !loading and userAbility.can 'read', data.project, 'Personel'
						# 		type: 'item'
						# 		key: 'personnel'
						# 		text: 'Personnel'
						# 		icon: users
						# 		isSelected: @matchPath '/projects/:project/personnel', false
						# 		href: "/projects/#{@props.project}/personnel"
					]
				]
				div {className: styles.sectionsWrapper},
					_.map (_.reject sections, (s) -> _.isEmpty s.items), (section) =>
						div {className: styles.section, key: section.key},
							_.map section.items, (item) =>
								@renderItem(item)
