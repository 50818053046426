###
Common way of representing floor information on the left of the building
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

# Styles
import styles from './index.styl'


export default class HeaderBar extends React.Component
	@propTypes:
		appearance: PropTypes.oneOf [
			'default'
			'primary'
			'subtle'
			'warning'
			'danger'
			'success'
		]
		document: PropTypes.bool
		compact: PropTypes.bool
		header: PropTypes.node.isRequired
		descriptor: PropTypes.node
		toolbar: PropTypes.oneOfType [
			PropTypes.node
			PropTypes.object
		]
	@defaultProps:
		document: false
		compact: false
		appearance: 'default'
	render: ->
		div {
			className: cnames [
				@props.className
				styles.base
				if @props.document then styles.documentBase
				if @props.compact then styles.compact
				styles[@props.appearance]
			]
		},
			div {className: cnames [
				styles.top
			]},
				@props.header
				do => if @props.descriptor?
					div {className: styles.descriptor}, @props.descriptor
			do => if @props.message?
				div {className: styles.message}, @props.message
			do => if @props.toolbar?
				div {className: cnames [
					styles.toolbar
				]}, @props.toolbar
