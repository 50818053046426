###
Revenue HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Views
DocumentsView = Loadable
	loader: -> `import(/* webpackChunkName: "legal-documents-view" */'../ProjectLegalDocuments')`
	loading: -> div {}

Overview = Loadable
	loader: -> `import(/* webpackChunkName: "legal-overview-view" */'../ProjectLegalOverview')`
	loading: -> div {}

ArchiveView = Loadable
	loader: -> `import(/* webpackChunkName: "legal-archive-view" */'../ProjectLegalArchive')`
	loading: -> div {}

# Data
import { RootQuery } from './data'
import trLegal from 'data/local/legal'
# Styles
import styles from './index.styl'


export default class DMSView extends React.Component
	constructor: (props) ->
		super props
		@data = {}
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				if !_.isEmpty data
					@data = data

				RouterTabs
					className: styles.tabs
					location: @props.location
					header: ApplicationHeader {
						loading: loading
						project: if !loading then @data.project.name
						application: trLegal.global.module
					}
					tabs: [
							label: trLegal.global.overview
							content: div {className: styles.tabContent},
								React.createElement Overview, @props
							href: "/projects/#{@props.match.params.project}/legal"
							catchAll: true
							exact: true
							id: 1
						,
							label: trLegal.global.documents
							content: div {className: styles.tabContent},
								React.createElement DocumentsView, @props
							href: "/projects/#{@props.match.params.project}/legal/documents"
							id: 2
						,
							label: trLegal.global.archive
							content: div {className: styles.tabContent},
								React.createElement ArchiveView, @props
							href: "/projects/#{@props.match.params.project}/legal/archive"
							id: 3
					]
