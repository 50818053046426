
###
Global notification manager
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, button } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

Fragment = React.createFactory React.Fragment

# Styles
import styles from './index.styl'
import {
	checkCircle
	alertCircle
	alertTriangle
	xCircle
	} from 'react-icons-kit/feather'


NotificationManagerContext = React.createContext()
NotificationManagerProvider = React.createFactory NotificationManagerContext.Provider
export NotificationConsumer = React.createFactory NotificationManagerContext.Consumer

iconMap = (appearance) ->
	switch appearance
		when 'normal' then alertCircle
		when 'success' then checkCircle
		when 'warning' then alertTriangle
		when 'error' then xCircle

export default class NotificationManager extends React.Component
	# @propTypes:
	constructor: (props) ->
		super props
		@state =
			notifications: []

	setNotification: ({content, appearance = 'normal'}) =>
		updatedNotifications = [...@state.notifications, {content: content, appearance: appearance}]
		@setState {notifications: updatedNotifications}, () =>
			setTimeout =>
				reducedNotifications = _.reject(@state.notifications, {content: content})
				@setState {notifications: reducedNotifications}
			, 2500

	render: ->
		NotificationManagerProvider
			value:
				setNotification: @setNotification
		,
			if !_.isEmpty @state.notifications
				div {className: styles.notificationsContainer},
					_.map @state.notifications, (notification, index) =>
						if notification.appearance is 'custom'
							if typeof notification.content is 'string' then notification.content else notification.content()
						else
							div {
								key: index
								className: cnames styles.toast, styles[notification.appearance]
							},
								if iconMap(notification.appearance)?
									Icon {className: styles.icon, icon: iconMap(notification.appearance), size: 20}
								if typeof notification.content is 'string' then notification.content else notification.content()
			@props.children
