###
PDF Viewer
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import { Document as _Document, Page as _Page } from 'react-pdf'
Document = React.createFactory _Document
Page = React.createFactory _Page

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'
import {
	rotateCcw
	rotateCw
	chevronsLeft
	chevronLeft
	chevronRight
	chevronsRight
	zoomIn
	zoomOut
} from 'react-icons-kit/feather'

export default class PDFPreview extends React.Component
	@defaultProps =
		enableRotating: false
		enableScaling: false
		scale: 1.2
		rotate: 0
		pageNumber: 1

	constructor: (props) ->
		super props
		@state =
			numPages: null
			pageNumber: @props.pageNumber
			rotate: @props.rotate
			scale: @props.scale

	onDocumentLoadSuccess: ({ numPages }) =>
		@setState { numPages }

	handlePageChange: (pageNumber) =>
		@setState pageNumber: pageNumber

	handleRotateChange: (rotate) =>
		if rotate < 0
			rotate = 360 + rotate
		else if rotate > 270
			rotate = 0
		@setState rotate: rotate

	handleScaleChange: (scale) =>
		if scale > 1.5
			scale = 1.5
		else if scale < 0.5
			scale = 0.5
		@setState scale: scale

	renderButton: (content, onClick, classNames) =>
		div
			onClick: onClick
			className: cnames(styles.item, classNames)
		,
			if _.isString content
				content
			else
				Icon {icon: content, size: 16}

	renderPageRotationButtonGroup: () =>
		{ rotate } = @state
		div {className: styles.buttonGroup},
			@renderButton(rotateCcw, (() => @handleRotateChange(rotate - 90)), styles.clickableItem)
			@renderButton('Reset rotation', (() => @handleRotateChange(0)), styles.clickableItem)
			@renderButton(rotateCw, (() => @handleRotateChange(rotate + 90)), styles.clickableItem)

	renderPageChangeButtonGroup: () =>
		{ pageNumber, numPages } = @state
		div {className: styles.buttonGroup},
			div {
				className: cnames styles.item, if pageNumber is 1 then styles.disabledItem else styles.clickableItem
				onClick: () => if pageNumber isnt 1 then @handlePageChange(1)
				}, Icon {icon: chevronsLeft, size: 16}

			div {
				className: cnames styles.item, if pageNumber is 1 then styles.disabledItem else styles.clickableItem
				onClick: () => if pageNumber isnt 1 then @handlePageChange(pageNumber - 1)
				},  Icon {icon: chevronLeft, size: 16}

			div {className: styles.item}, "#{pageNumber} of #{numPages}"

			div {
				className: cnames styles.item, if pageNumber is @state.numPages then styles.disabledItem else styles.clickableItem
				onClick: () => if pageNumber isnt @state.numPages then @handlePageChange(pageNumber + 1)
			}, Icon {icon: chevronRight, size: 16}

			div {
				className: cnames styles.item, if pageNumber is @state.numPages then styles.disabledItem else styles.clickableItem
				onClick: () => if pageNumber isnt @state.numPages then @handlePageChange(@state.numPages)
				}, Icon {icon: chevronsRight, size: 16}

	renderPageScaleButtonGroup: () =>
		{ scale } = @state
		div {className: styles.buttonGroup},
			div {
				className: cnames styles.item, if scale is 0.5 then styles.disabledItem else styles.clickableItem
				onClick: () => if scale isnt 0.5 then @handleScaleChange(scale / 1.1 )
				}, Icon {icon: zoomOut, size: 16}

			div {
				className: cnames styles.item, styles.clickableItem
				onClick: () => @handleScaleChange(@props.scale)
				}, 'Reset zoom'

			div {
				className: cnames styles.item, if scale is 1.5 then styles.disabledItem else styles.clickableItem
				onClick: () => if scale isnt 1.5 then @handleScaleChange(scale * 1.1)
				}, Icon {icon: zoomIn, size: 16}

	render: ->
		{ pageNumber, numPages, rotate, scale } = @state
		Document
			file: @props.scan
			onLoadSuccess: @onDocumentLoadSuccess
			loading: Spinner {}
			className: styles.base
		,
			Fragment {},
				div {className: styles.toolbar},
					if @props.enableRotating
						@renderPageRotationButtonGroup()
					@renderPageChangeButtonGroup()
					if @props.enableScaling
						@renderPageScaleButtonGroup()
				Page
					className: styles.pdfDocument
					pageNumber: pageNumber
					rotate: rotate
					scale: scale
