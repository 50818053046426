import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment'
import {Link as _Link} from 'react-router-dom'

import { div, p, span, button, h5, a, input} from 'react-dom-factories'
import _Badge from '@atlaskit/badge'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon
import {ic_star} from 'react-icons-kit/md/ic_star'

import {QuickSearch as _QuickSearch} from '@atlaskit/quick-search'
QuickSearch = React.createFactory _QuickSearch

Link = React.createFactory _Link
Badge = React.createFactory _Badge
Fragment = React.createFactory React.Fragment

import styles from './index.styl'


Tile = ({ item, group, onClick }) ->
	div
		key: item.name
		className: cnames styles.accessPoint, styles.tile
		onClick: onClick
	,
		div { className: cnames styles.icon },
			Icon icon: item.icon
		div { className: cnames styles.accessPointDetails },
			p { className: cnames styles.label },
				item.name
			if item.description?
				p { className: cnames styles.description },
					item.description
		do ->
			div {className: cnames styles.tags },
				if item.isFavourite
					span { className: cnames styles.tag },
						Icon icon: ic_star
				# if item.lastOpened?
				# 	span { className: cnames styles.tag },
				# 		EditorRecentIcon {primaryColor: '#5e6c84'}

Card = ({ item, group, onClick, onAddToFavourites }) ->
	div
		key: item.name
		className: cnames styles.accessPoint, styles.card
		onClick: onClick
	,
		div
			className: cnames styles.image
			style:
				if item.image?
					backgroundImage: "url(#{item.image})"
				else if item.bigIcon?
					backgroundImage: "url(#{item.bigIcon})", backgroundPosition: 'center', backgroundSize: '30%'
		div { className: cnames styles.accessPointDetails },
			p { className: cnames styles.label },
				item.name
			p { className: cnames styles.description },
				item.description
			do ->
				div {className: cnames styles.tags },
					do ->
						color = if item.isFavourite then '#FFC200' else '#5e6c84'
						div
							className: cnames styles.tag, if item.isFavourite then styles.fav
							onClick: (e) ->
								do ->
									e.stopPropagation()
									onAddToFavourites()
						,
							Icon icon: ic_star
					if item.lastOpened?
						div {className: cnames styles.tag, styles.lastOpened},
							'opened: '
							moment(item.lastOpened).format('DD MMM')


export default class AppSwitcher extends React.Component
	@defaultProps =
		tileComponent: React.createFactory Tile
		cardComponent: React.createFactory Card
		onAccessPointSelect: ->
		onAddToFavourites: ->
		tilesInQucikView: 5
		isExpandable: true

	@propTypes =
		accessPoints: PropTypes.arrayOf PropTypes.shape
			name: PropTypes.string
			items: PropTypes.arrayOf PropTypes.shape
				name: PropTypes.string
				image: PropTypes.node
				# icon: PropTypes.element # FIXME: Invalid use gives warning
				description: PropTypes.string
				href: PropTypes.string
		onSelect: PropTypes.func
		onAddToFavourites: PropTypes.func
		tilesInQucikView: PropTypes.number

	constructor: (props, context) ->
		super props, context
		@state =
			isExtended: false
			searchQuery: ''

	handleViewAllClick: =>
		@props.onExpand()
		@setState isExtended: !@state.isExtended

	search: (query) =>
		@setState searchQuery: query
		results = @searchData(query)
		# @setState results: results

	# handleKeyNavigation: (e) ->
	# 	if e.keyCode is 40
	# 		console.log e.target

	applySearchQuery: (items, query) ->
		if query == '' then return items
		else
			results = items.filter (item) -> _.startsWith(item.name.toLowerCase(), query.toLowerCase())

	renderExpandedView: =>
		Fragment {},
			div {className: styles.topBar},
				div
					className: cnames styles.viewAllButton
					onClick: => @handleViewAllClick()
				,
					'Back'
				div {className: styles.searchBar},
					input
						className: styles.search
						placeholder: 'What are you looking for?'
						onChange: (e) => @setState searchQuery: e.target.value
						value: @state.searchQuery
						autofocus: 'true'
				div {},
			div { className: cnames styles.groups },
				# groups
				do =>
					@props.accessPoints.map (group) =>
						items = @applySearchQuery(group.items, @state.searchQuery)
						if items.length > 0
							div
								key: group.name
								className: cnames styles.groupContainer
							,
								h5 {className: cnames styles.groupHeader},
									span {}, group.name
									Badge {}, group.items.length
								div {className: styles.groupRow},
									items.map (item) =>
										Link {to: item.href, key: item.name, className: styles.cardWrapper},
											@props.cardComponent
												group: group
												item: item
												onClick: () => @props.onSelect(item, group)
												onAddToFavourites: () => @props.onAddToFavourites(item, group)

	renderQuickView: =>
		Fragment {},
			div {className: styles.topBar},
				div {className: styles.searchBar},
					input
						className: styles.search
						placeholder: 'What are you looking for?'
						onChange: (e) => @setState searchQuery: e.target.value
						# onKeyDown: (e) => @handleKeyNavigation(e)
						value: @state.searchQuery
						autofocus: 'true'
			div {className: cnames styles.groups},
				# groups
				do =>
					@props.accessPoints.map (group) =>
						div
							key: group.name
							className: cnames styles.groupContainer
						,
							h5 {className: cnames styles.groupHeader},
								group.name
							do =>
								items = @applySearchQuery(group.items, @state.searchQuery)
								# favourites
								favourites = items.filter((item) -> item.isFavourite is true)
								favoritesToRender = _.take(favourites, @props.tilesInQucikView)
								# used recently
								usedRecently = _.difference(items, favourites).filter((item) -> item.lastOpened?)
								usedRecently = _.orderBy(usedRecently, 'lastOpened', 'desc')
								usedRecentlyToRender = _.take(usedRecently, @props.tilesInQucikView - favoritesToRender.length)
								# rest
								rest = _.difference(items, usedRecently, favourites)
								restToRender = _.take(rest, @props.tilesInQucikView - favoritesToRender.length - usedRecentlyToRender.length)
								# render
								_.concat(favoritesToRender, usedRecentlyToRender, restToRender).map (item) =>
									Link {to: item.href, key: item.name, className: styles.tileWrapper},
										@props.tileComponent
											group: group
											item: item
											onClick: () => @props.onSelect(item, group)
			if @props.isExpandable
				div
					className: cnames styles.viewAllButton
					onClick: => @handleViewAllClick()
				,
					'View all'

	render: ->
		div
			className: cnames @props.className, styles.appSwitcher, '@bevy-app-switcher', if @state.isExtended then styles.expanded else styles.quickView
		,
			if @state.isExtended then @renderExpandedView() else @renderQuickView()
