import gql from 'graphql-tag'
import {
	LegalDocument
	LegalDocumentHeader
	MetaInfo
	LegalDocumentAdditionalInfo
	ACL
} from './query.graphql'

export DocumentPreview = gql """
	query($documentID: ID!){
		legalDocument(id: $documentID) {
			#{LegalDocument}
			#{LegalDocumentAdditionalInfo}
			#{LegalDocumentHeader}
			#{MetaInfo}
			#{ACL}
		}
	}
"""

export DuplicateDocument = gql """
	mutation($basics: LegalDocumentBasicInput!, $details: LegalDocumentDetailsInput) {
		legalDocumentCreateByCategory(basics: $basics, details: $details) {
			id
		}
	}
"""

export DocumentPreviewAdditonalInfo = gql """
	query($documentID: ID!){
		legalDocument(id: $documentID) {
			#{LegalDocumentAdditionalInfo}
		}
	}
"""

export getDocumentCombinationsFromPath = gql """
	query($pathID: ID!) {
		legalDocumentPath(id: $pathID) {
			id
			combinations {
				formID
				topicID
				building
				dates { type, isRequired }
				identifiers { type, isRequired }
				entities { type, isRequired, isMulti }
			}
		}
	}
"""


export ReassignDocument = gql """
	mutation($documentID: ID!, $assignee: LegalDocumentAssigneeInput!) {
		legalDocumentsUpdateAssignee(documentIDs: [$documentID], value: $assignee) {
			id
			asignee {
				... on Person { id, name }
				... on Service { id, name }
			}
		}
	}
"""

export ListDocumentScans = gql """
	query {
		legalDocumentScans(isMapped: false) {
			id
		}
	}
"""

export MapScan = gql """
	mutation($documentID: ID!, $scanID: ID!){
		legalDocumentMapScan(documentID: $documentID, scanID: $scanID) {
			id
			hasScan
			scan { original, id }
		}
	}
"""
export UpdateReviewed = gql """
	mutation($documentID: ID!, $reviewed: Boolean!){
		legalDocumentUpdateReviewed(documentID: $documentID, value: $reviewed) {
			id
			status
			modifiedAt
			modifiedBy {
				... on Person { id, name }
			}
			hasReview
		}
	}
"""

export UpdateApproved = gql """
	mutation($documentID: ID!, $approved: Boolean!){
		legalDocumentUpdateApproval(documentID: $documentID, value: $approved) {
			id
			status
			modifiedAt
			modifiedBy {
				... on Person { id, name }
			}
			hasApproval
			approvedAt
			approvedBy {
				id
				name
			}
		}
	}
"""

export getEntities = gql """
	query {
		organizations {
			name
			id
		}
	}
"""

export getUsers = gql """
	query {
		users { name, id }
	}
"""

export UpdateDate = gql """
	mutation($documentID: ID!, $type: LegalDocumentDateType!, $value: Int!){
		legalDocumentUpdateDate(documentID: $documentID, type: $type, value: $value) {
			id
			dates { type, value }
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id, picture { mini } }
			}
		}
	}
"""

export UpdateIdentifier = gql """
	mutation($documentID: ID!, $type: LegalDocumentIdentifierType!, $value: String!){
		legalDocumentUpdateIdentifier(documentID: $documentID, type: $type, value: $value) {
			id
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id picture { mini } }
			}
			identifiers { type, value }
		}
	}
"""

export UpdateRecipient = gql """
	mutation($documentID: ID!, $value: ID!){
		legalDocumentUpdateRecipient(documentID: $documentID, value: $value) {
			id
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id, picture { mini } }
			}
			entities { recipient { id } }
		}
	}
"""
export UpdateParticipants = gql """
	mutation($documentID: ID!, $value: [ID!]!){
		legalDocumentUpdateParticipants(documentID: $documentID, value: $value) {
			id
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id, picture { mini } }
			}
			entities { participants { id, name } }
		}
	}
"""

export UpdateDescription = gql """
	mutation($documentID: ID!, $value: String!){
		legalDocumentUpdateDescription(documentID: $documentID, value: $value) {
			id
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id picture { mini } }
			}
			description
		}
	}
"""

export UpdateBuildings = gql """
	mutation($documentID: ID!, $value: [String!]!){
		legalDocumentUpdateBuildings(documentID: $documentID, value: $value) {
			id
			status
			modifiedAt
			modifiedBy {
				...on Person { name, id picture { mini } }
			}
			buildings
		}
	}
"""

export UpdateACL = gql """
	mutation($documentID: ID!, $value: [AccessInput!]!){
		legalDocumentUpdateACL(documentID: $documentID, value: $value) {
			id
			modifiedAt
			modifiedBy {
				...on Person { name, id picture { mini } }
			}
			acl {
				user {
					... on Person {
						id
						name
						picture {
							mini
						}
					}
				}
			userID
			access {
				scopes
				level
			}
			}
		}
	}
"""

export DeleteDocument = gql """
	mutation($documentID: ID!){
		legalDocumentDelete(documentID: $documentID) {
			id
		}
	}
"""

export getAccessList = gql """
	query($documentID: ID!){
		legalDocument(id: $documentID) {
			id
			acl {
				user {
					... on Person {
						id
						name
						picture {
							mini
						}
					}
				}
			userID
			access {
				scopes
				level
			}
			}
		}
	}
"""
