###
Opportunity panel presents opportunity in side panel with details about offer split into multiple tabs
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
import {FormatterConsumer} from 'libs/formatter'
# Renderable
import { div, p, img } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button


import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import { Query as _Query, Mutation as _Mutation} from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import { Route as _Route, Link as _Link} from 'react-router-dom'
Route = React.createFactory _Route
Link = React.createFactory _Link

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _SidePanel from '@bevy/side-panel'
SidePanel = React.createFactory _SidePanel

# Views
import _ObservationHeader from './components/ObservationHeader'
ObservationHeader = React.createFactory _ObservationHeader

import _Info from './components/Info'
Info = React.createFactory _Info

# Data
import {
	ObservationQuery
	Remove
	MarkAsChecked
	MarkAsPending
	SyncToBim
} from './data'

# Styles
import styles from './index.styl'


export default class ObservationPanel extends React.Component
	render: ->
		SidePanel
			active: @props.observation?
			# closeComponent: @props.closeLink
			renderCloseButton: false
 			# alignLeft: true
			className: styles.sidePanel
			,
				Route
					path: @props.route
					exact: false
					render: ({match, location}) =>
						Query
							query: ObservationQuery
							variables:
								id: match.params.observation
						, ({data, loading, error}) =>
							if loading
								Spinner {size: 'large'}
							else
								observation = data.ehsObservation
								div {
									'data-test-id': DataAttribute 'observation-panel'
									className: styles.base
								},
									Mutation {
										mutation: Remove
										onCompleted: @props.onDelete
									}, (removeObservation, {loading: removeLoading}) =>
										Mutation {
											mutation: MarkAsChecked
										}, (markAsChecked, {loading: markAsCheckedLoading}) =>
											Mutation {
												mutation: MarkAsPending
											}, (markAsPending, {loading: markAsPendingLoading}) =>
												Mutation {
													mutation: SyncToBim
												}, (syncToBim, {loading: syncingLoading}) =>
													Fragment {},
														ObservationHeader {
															inReview: @props.inReview
															...observation
															className: styles.details
															'data-test-id': DataAttribute "Observation-Details: #{observation.id}"
															delete: ->
																removeObservation
																	variables: observationID: observation.id
															linkIssue: ->
															checked: ->
																markAsChecked	variables: observationID: observation.id
															pending: ->
																markAsPending	variables: observationID: observation.id
															syncToBim: ->
																syncToBim variables:
																	observationID: observation.id
																	status: 'Open'
																	companyID: '3f1857e6-b261-4297-bdad-ef5c4607d0da' # Skanska SA
																	dueDate: observation.dueDate
														}
														RouterTabs
															location: location
															tabs: [
																	id: 0
																	label: 'Details'
																	href: "#{match.url}"
																	exact: true
																	content: div {className: styles.tabPanel},
																		React.createElement Info, {...observation}
																,
																	id: 1
																	label: 'Attachments'
																	href: "#{match.url}/preview"
																	exact: true
																	content: div {className: styles.tabPanel},
																		do ->
																			if _.isEmpty observation.photos
																				p {}, 'There are no photos in this observation'
																			else
																				console.log observation.photos
																				div {},
																					_.map observation.photos, (photo, idx) ->
																						img
																							key: idx
																							className: styles.image
																							src: observation.photos[idx].original
																# ,
																# 	id: 3
																# 	label: 'History'
																# 	href: "#{match.url}/activities"
																# 	exact: true
																# 	content: div {className: styles.tabPanel}, _.map [0..20], (index) ->
																# 		SkeletonBox {key: index, className: styles.itemBox}, 'Activity'
																# ,
																# 	id: 4
																# 	label: 'Activities'
																# 	href: "#{match.url}/activities"
																# 	exact: true
																# 	content: div {className: styles.tabPanel}, _.map [0..20], (index) ->
																# 		SkeletonBox {key: index, className: styles.itemBox}, 'Activity'
																# ,
																# 	id: 5
																# 	label: 'Comments'
																# 	href: "#{match.url}/comments"
																# 	exact: true
																# 	content: div {className: styles.tabPanel}, _.map [0..20], (index) ->
																# 		SkeletonBox {key: index, className: styles.itemBox}, 'Comment'
															]

