###
S360 application / platform layout and navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import storage from 'libs/storage'
import dndContext from 'libs/dndContext'
import { modeGenerator } from '@atlaskit/navigation-next'
import { withAdalLoginApi, getUser, getToken, login} from '../wrapper'
import userAbility from '@bevy/s360-permissions'

import {
	matchPath
	withRouter
} from 'react-router-dom'
# Renderable
import { div, p } from 'react-dom-factories'

# Data
import { LoggedInUser } from './data'

Fragment = React.createFactory React.Fragment

import { ApolloProvider as _ApolloProvider } from 'react-apollo'
ApolloProvider = React.createFactory _ApolloProvider

import { AbilityContext } from 'components/UserAbility'
AbilityProvider = React.createFactory AbilityContext.Provider

import { ThemeProvider as _ThemeProvider } from 'emotion-theming'
ThemeProvider = React.createFactory _ThemeProvider

import {
	NavigationProvider as _NavigationProvider
	LayoutManager as _LayoutManager
	Section as _Section
} from '@atlaskit/navigation-next'
NavigationProvider = React.createFactory _NavigationProvider
LayoutManager = React.createFactory _LayoutManager
Section = React.createFactory _Section

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

import {
	BrowserRouter as _BrowserRouter
	Route as _Route
	Switch as _Switch
	Link as _Link
	Redirect as _Redirect
} from 'react-router-dom'
BrowserRouter = React.createFactory _BrowserRouter
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link
Redirect = React.createFactory _Redirect

import _LoginScreen from './components/LoginScreen'
LoginScreen = React.createFactory _LoginScreen

import _NotificationManager from './components/NotificationManager'
NotificationManager = React.createFactory _NotificationManager

import _GlobalNavigation from './components/GlobalNavigation'
GlobalNavigation = React.createFactory _GlobalNavigation

import _ApplicationSwitcherDrawer from './components/ApplicationSwitcherDrawer'
ApplicationSwitcherDrawer = React.createFactory _ApplicationSwitcherDrawer

import _SearchDrawer from './components/SearchDrawer'
SearchDrawer = React.createFactory _SearchDrawer

import _DocumentDetails from 'parts/DocumentDetails'
DocumentDetails = React.createFactory _DocumentDetails

import {
	SpotlightManager as _SpotlightManager
} from '@atlaskit/onboarding'

SpotlightManager = React.createFactory _SpotlightManager

import MainRouter from './routing'

# Data
import {fixtures, schema, resolvers, indexators} from 'data'
# Styles
import styles from './index.styl'
import {
	home
	briefcase
	alignJustify
	fileText
} from 'react-icons-kit/feather'


# Navigation cache override
LS_KEY = 'ATLASKIT_NAVIGATION_UI_STATE'
CONTENT_NAV_WIDTH = 270
RESOLVER_URI = if __ENV__ is 'development' then 'http://localhost:8001/dev' else 'https://10llvmd8j1.execute-api.eu-central-1.amazonaws.com/production'
RESOLVER_URI = switch __STAGE__
	when 'test' then 'https://cyoryftm30.execute-api.eu-central-1.amazonaws.com/test'
	when 'staging' then 'https://u8c0fne9i2.execute-api.eu-central-1.amazonaws.com/staging/'
	when 'production' then 'https://8op5v0e8q3.execute-api.eu-central-1.amazonaws.com/production'
	else 'http://localhost:8001/dev'

UiCacheOverride =
	get: ->
		stored = localStorage.getItem LS_KEY
		if stored?
			saved = JSON.parse stored
			saved.isPeekHinting = false
			saved.isPeeking = false
			saved
		else
			isPeekHinting: false
			isPeeking: false
			isCollapsed: false
			productNavWidth: CONTENT_NAV_WIDTH
			isResizing: false
	set: (state) ->
		localStorage.setItem LS_KEY, JSON.stringify state


export default withRouter dndContext class S360Application extends React.Component
	constructor: (props) ->
		super props
		console.debug '[Resolver]', RESOLVER_URI
		@formatterMoney = new Intl.NumberFormat 'pl-PL',
			style: 'decimal'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
		@formatterInteger = new Intl.NumberFormat 'pl-PL',
			style: 'decimal'
			minimumFractionDigits: 0
			maximumFractionDigits: 0
		@formatterEur = new Intl.NumberFormat 'pl-PL',
			style: 'currency'
			currency: 'EUR'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
			currencyDisplay: 'symbol'
		@formatterPln = new Intl.NumberFormat 'pl-PL',
			style: 'currency'
			currency: 'PLN'
			minimumFractionDigits: 2
			maximumFractionDigits: 2
			currencyDisplay: 'symbol'
		@formatterSqm = new Intl.NumberFormat 'pl-PL',
				style: 'decimal'
				minimumFractionDigits: 2
				maximumFractionDigits: 2
		@formatterUnits = new Intl.NumberFormat 'pl-PL',
				style: 'decimal'
				minimumFractionDigits: 0
				maximumFractionDigits: 0
		@state =
			index: 0
			dbInitialised: false
			user: null
			drawers:
				appSwitcher: false
				search: false
	navigate: (path) =>
		(event) =>
			if event? and !event.ctrlKey and !event.metaKey
				event.preventDefault()
				@props.history.push path
			else if !event?
				@props.history.push path
	closeDrawer: (drawer) =>
		=>
			@setState drawers: {
				...@state.drawers
				"#{drawer}": false
			}
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?
	componentDidMount: ->
		storage.init fixtures.version, (err, db) =>
			storage.populate _.omit(fixtures, 'version'), false, indexators
			matcher = new IntrospectionFragmentMatcher introspectionQueryResultData: require './fragmentsMatcher.json'
			setUpClient = (token, user) =>
				@client = new ApolloClient
					link: createUploadLink
						uri: RESOLVER_URI
						clientState:
							typeDefs: schema
							resolvers: resolvers db
						headers:
							Authorization: "Bearer #{token}"
					cache: new InMemoryCache fragmentMatcher: matcher
					fetch: do ->
						if !document?
							require 'cross-fetch'
					headers:
						Authorization: "Bearer #{token}"
				@client.query(query: LoggedInUser)
				.catch (err) ->
					console.warn 'User query failed', err
				.then ({data}) =>
					user = data.me
					console.debug '[User]', user
					@ability = userAbility user, true
					@setState user: user
			if __STAGE__? and __STAGE__ isnt 'local'
				getUser (err, user) =>
					if err?
						@setState user: false
					else
						getToken (err, token) ->
							setUpClient token, user
			else
				setUpClient 'develop-token', 'test-user'
	login: ->
		login()
	render: ->
		Switch {},
			Route
				exact: true
				path: '/login'
				render: (props) =>
					if @state.user? and @state.user isnt false
						Redirect to: '/'
					else
						LoginScreen {onLogin: @login}
			# TODO refactor
			Route
				path: '/legalDocuments/:document'
				render: (props) =>
					if !@state.user?
						return Spinner {}
					else if @state.user is false
						return Redirect to: '/login'
					ApolloProvider {client: @client},
						AbilityProvider {value: @ability},
							NotificationManager {},
								DocumentDetails {
									mobile: true
									...props
								}

			Route
				render: (props) =>
					match = matchPath window.location.pathname,
						path: '/projects/:project'
					hasContainerNav = match?
					isBuildingEditor = !_.isEmpty matchPath(window.location.pathname,
						path: '/projects/:project/leasing/editor'
					)
					customThemeMode = modeGenerator
						product:
							text: '#FFF'
							background: '#0747A6'
					pageProps =
						format:
							eur: @formatterEur.format
							pln: @formatterPln.format
							money: @formatterMoney.format
							integer: @formatterInteger.format
							sqm: (val) => "#{@formatterSqm.format(val)} sqm."
							units: (val) => "#{@formatterUnits.format(val)} units"
						navigate: @navigate
						className: styles.page
					if !@state.user?
						return Spinner {}
					else if @state.user is false
						return Redirect to: '/login'
					content = React.createElement MainRouter, {...pageProps}


					ApolloProvider {client: @client},
						AbilityProvider {value: @ability},
							NavigationProvider {cache: UiCacheOverride},
								NotificationManager {},
									ThemeProvider {
										theme: (theme) ->
											{
												...theme
												mode: customThemeMode
											}
									}, do =>
										if isBuildingEditor
											content
										else
											Fragment {},
												SpotlightManager {blanketIsTinted: true},
													ApplicationSwitcherDrawer
														close: @closeDrawer 'appSwitcher'
														isOpen: @state.drawers.appSwitcher
													SearchDrawer
														close: @closeDrawer 'search'
														isOpen: @state.drawers.search
													GlobalNavigation
														hasContainerNav: hasContainerNav
														navigate: @navigate
														openDrawer: (drawer) =>
															=>
																@setState drawers: {
																	...@state.drawers
																	"#{drawer}": true
																}
														, content
